import { HttpErrorResponse } from '@angular/common/http';
import { reloadEvent } from './../../services/EventEmitters';
import { UtilityService } from './../../services/utility.service';
import { ITaskSummary } from '../../../app/models/task-summary.interface';
import { Component, EventEmitter, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Subscription, debounceTime, finalize, switchMap, of, filter } from 'rxjs';
import { DashboardService } from '../../../app/services/dashboard.service';
import { AssigneeData } from '../../../app/models/assignee.interface';
import { getUserInfo, CommonMessages } from '@igps/client-root-config';

@Component({
  selector: 'app-re-assign-popup-email',
  templateUrl: './re-assign-popup-email.component.html',
  styleUrls: ['./re-assign-popup-email.component.scss']
})
export class ReAssignPopupEmailComponent implements OnInit , OnDestroy {

  search: EventEmitter<string> = new EventEmitter<string>();
  searchEventSubscription!: Subscription;

  panelOpenState = false;
  assignees!: AssigneeData[];
  assigneesBackup!: AssigneeData[];

  selectedAssigneeId!: string;
  searchText: string = "";
  isUsersLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<ReAssignPopupEmailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      heading: string;
      rowData: ITaskSummary;
      processId: string;
      message: string;
      labels: string[];
      size: string;
      align: string;
      config: any;
    },
    private dashservice : DashboardService,
    private utilityService : UtilityService,
    private cdref: ChangeDetectorRef
  ) { }

  ngOnDestroy() {
    this.searchEventSubscription.unsubscribe();
  }

  ngOnInit() {
    this.getAssigneeData();
    const searchObservable = this.search.asObservable().pipe(
      map(d => {
        this.cdref.detectChanges();
        this.isUsersLoading = true;
        return d;
      }),
      map(d => {
        this.assignees = [];
        if(d.trim().length === 0){
          this.isUsersLoading = false;
          this.assignees = JSON.parse(JSON.stringify(this.assigneesBackup)) as AssigneeData[];
          this.cdref.detectChanges();
        }
        return d;
      }),
      filter(d => d.trim().length > 0),
      debounceTime(500),
      switchMap(d => {
        const prdsysId = getUserInfo().productionSystem.id;
        return this.dashservice.getAssigneeOnSearch(prdsysId, d).pipe(
          finalize(() => {
            this.isUsersLoading = false;
            this.cdref.detectChanges();
          })
        );
      })
    );
    this.searchEventSubscription = searchObservable.subscribe(assigneeData => {
      if(this.searchText.trim().length !== 0){
        assigneeData = assigneeData.map(d => {
          return {...d, role: d.role.charAt(0).toUpperCase() + d.role.slice(1).toLowerCase()};
        });
        this.assignees = assigneeData;
      }
      this.cdref.detectChanges();
    });
  }

  getAssigneeData() {
    this.utilityService.setSpinner(true);
    this.assignees = [];
    this.dashservice.getAssigneeData(this.data.processId).pipe(
      finalize(() => {
        this.utilityService.setSpinner(false);
      })
    ).subscribe({
        next: (result: AssigneeData[]) => {
          const UserInfo = getUserInfo();
          let myself: AssigneeData | undefined = result.find(o => o.id === UserInfo.id);
          if(myself){
            result = result.filter(o => o.id !== (myself as AssigneeData).id);
          }
          else{
            myself = {
              id: UserInfo.id,
              name: `Myself`,
              role: UserInfo.role.name,
              site: UserInfo.site.name
            }
          }
          // if(this.data.rowData.assigneeId !== myself.id){
            result.unshift({...myself, name: "Myself"});
          // }
          result = result.map(d => {
            return {...d, role: d.role.charAt(0).toUpperCase() + d.role.slice(1).toLowerCase()};
          })
          // if(this.data.rowData.assigneeId){
            result.unshift({
              id: "null",
              name: "Unassigned",
              role: "",
              site: ""
            });
          // }
          this.assignees = result;
          this.assigneesBackup = JSON.parse(JSON.stringify(this.assignees));
          this.selectedAssigneeId = this.data.rowData.assigneeId ? this.data.rowData.assigneeId : 'null';
          this.cdref.detectChanges();
        },
        error: (error: HttpErrorResponse) => {
          if(error.error.Type === "HttpRequestException"){
            this.utilityService.showToast.emit({ message: error.error.Title, isError: true});
          }
          else{
            this.utilityService.showToast.emit({ message: error.error.Detail, isError: true});
          }
        }
    });
  }

  close(message?: string): void {
    this.dialogRef.close(message?.toLowerCase());
    this.cdref.detectChanges();
  }

  onSave(){
    if(this.selectedAssigneeId === "null"){
      this.utilityService.setSpinner(true);
      this.dashservice.UnAssignEmail(this.data.rowData.id).pipe(
        finalize(() => {
          this.utilityService.setSpinner(false);
        })
      ).subscribe({
        next: (d) => {
          this.close();
          const assignee = this.assignees.find(a => a.id === this.selectedAssigneeId);
          this.utilityService.showToast.emit({ message: `This email is Unassigned`, isError: false});
          reloadEvent.emit();
        },
        error: (error) => {
          this.assignees = JSON.parse(JSON.stringify(this.assigneesBackup));
          this.selectedAssigneeId = this.data.rowData.assigneeId ? this.data.rowData.assigneeId : 'null';
          this.cdref.detectChanges();
          if(error.error.Type === "HttpRequestException"){
            this.utilityService.showToast.emit({ message: error.error.Title, isError: true});
          }
          else{
            this.utilityService.showToast.emit({ message: error.error.Detail, isError: true});
          }
        }
      });
    }
    else{
      this.utilityService.setSpinner(true);
      this.dashservice.ReAssignEmail(this.data.rowData.id,this.selectedAssigneeId).pipe(
        finalize(() => {
          this.utilityService.setSpinner(false);
        })
      ).subscribe({
        next: (d) => {
          this.close();
          const assignee = this.assignees.find(a => a.id === this.selectedAssigneeId);
          this.utilityService.showToast.emit({ message: `This email is Assigned to ${assignee?.name}`, isError: false});
          reloadEvent.emit();
        },
        error: (error) => {
          this.assignees = JSON.parse(JSON.stringify(this.assigneesBackup));
          this.selectedAssigneeId = this.data.rowData.assigneeId ? this.data.rowData.assigneeId : 'null';
          this.cdref.detectChanges();
          if(error.error.Type === "HttpRequestException"){
            this.utilityService.showToast.emit({ message: error.error.Title, isError: true});
          }
          else{
            this.utilityService.showToast.emit({ message: error.error.Detail, isError: true});
          }
        }
      });
    }
  }

}
