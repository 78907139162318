import { Component, OnInit } from '@angular/core';
import { notesClickEvent, selectedTaskId } from '@igps/top-utility';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ITaskSummary } from '../../../app/models/task-summary.interface';

@Component({
  selector: 'app-notes-cell-renderer',
  template: `
    <div class="notes-container" *ngIf="rowValue !== undefined">
      <span (click)="onNotesClick()">
        <!-- <aside class="number" [ngClass]="{'more-notes': +cellValue>9 }" [style]="+cellValue == 0 ? 'display: none;' : 'display: flex;' "> {{cellValue}} </aside>
        <svg [style]="+cellValue == 0 ? 'margin-top: 10px;' : '' " viewBox="0 0 24 24" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m19.803 1.575 2.122 2.122a1 1 0 0 1 0 1.414L21 6.034V20a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h12.964l.425-.425a1 1 0 0 1 1.414 0ZM16.964 3H5a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7.034l-6.56 6.562a1 1 0 0 1-.294.203l-3.89 1.768c-.84.383-1.706-.483-1.323-1.324L9.7 10.354a1 1 0 0 1 .203-.293l7.06-7.062.001.001Zm-6.795 8.741-1.326 2.917 2.917-1.326-1.591-1.591Zm6.806-7.336-6.364 6.364 2.121 2.121 6.364-6.364-2.121-2.121Zm2.121-2.122-1.414 1.415 2.121 2.121 1.415-1.414-2.122-2.122Z" fill="#043B7C"></path>
        </svg> -->
        <lib-icon name="pencil_message" size="standard" style="vertical-align: middle"></lib-icon>
      </span>
    </div>
  `,
  styles: [
  ]
})
export class NotesCellRendererComponent implements ICellRendererAngularComp {

  cellValue!: string;
  rowValue!: ITaskSummary;

  agInit(params: ICellRendererParams<any, any>): void {
    this.cellValue = params.value;
    this.rowValue = params.data;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  onNotesClick() {
    notesClickEvent.emit(this.rowValue.id);
    if (this.rowValue.taskId) {
      selectedTaskId.emit(this.rowValue.taskId)
    }
  }

}
