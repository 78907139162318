import { Component, NgZone, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IEmailSummary } from '../../../app/models/task-summary.interface';
import { Router } from '@angular/router';
import { EmailDashboardService } from '../../services/emaildashboard.service';

@Component({
  selector: 'app-email-cell-renderer',
  templateUrl: './email-cell-renderer.component.html',
  styleUrls: ['./email-cell-renderer.component.scss']
})
export class EmailCellRendererComponent implements ICellRendererAngularComp {

  cellValue!: string;
  rowValue!: IEmailSummary;
  emailInfo: any;
  constructor(private router: Router, private emaildashservice: EmailDashboardService, private zone: NgZone) { }
  agInit(params: ICellRendererParams<any, any>): void {
    this.cellValue = params.value;
    this.rowValue = params.data;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }
  onEmailClick() {

    this.emaildashservice.openEmailSummaryDialog(this.rowValue);
  }
}
