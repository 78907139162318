<div class="main-container">

  <hr class="dashboard-divider">

  <div>
    <span class="heading">
      Task Summary
    </span>
    <br>
    <span class="updated-text"> Updated {{updatedTime | convertTime: 'local' : timezone.value }} </span>
    <button class="tertiary" (click)="refreshGrid()">
      <lib-icon name="refresh" size="small"></lib-icon>
      Reload
    </button>
  </div>

  <div class="search-container">
    <div class="label-input-labelwrapper">
      <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
        <mat-label id="searchTasks" class="mat-label-wrap">Search Tasks</mat-label>
        <input class="mat-input-element-show-placeholder" id="searchTasks" matInput
          placeholder="Search by File Number, Property Address" (keyup)="search.emit($event)"
          [(ngModel)]="searchText" />
        <svg *ngIf="searchText.trim().length > 0" (click)="clearSearch()" class="clear-icon" height="16"
          viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 3a9 9 0 1 1 0 18.001A9 9 0 0 1 12 3ZM7.05 7.05a1 1 0 0 0-.083 1.32l.083.094L10.586 12 7.05 15.536a1 1 0 0 0-.083 1.32l.083.094a1 1 0 0 0 1.414 0L12 13.414l3.536 3.536a1 1 0 0 0 1.497-1.32l-.083-.094L13.414 12l3.536-3.536a1 1 0 0 0 .083-1.32l-.083-.094a1 1 0 0 0-1.414 0L12 10.586 8.464 7.05a1 1 0 0 0-1.414 0Z"
            fill="#043B7C"></path>
        </svg>
        <lib-icon class="search-task-icon" name="magnifying_glass" size="standard"></lib-icon>
      </mat-form-field>
    </div>

  </div>

  <div class="grid-container">
    <igps-lib-spinner class="spinner" [showSpinner]="(showGridSpinner)"></igps-lib-spinner>
    <app-manager-grid (updateTime)="updateTime()" [refreshgrid]="refreshGridData"></app-manager-grid>
    <!-- <app-processor-grid (updateTime)="updateTime()" *ngIf="isProcessor"></app-processor-grid> -->
  </div>
  <div class="btnAddtask" *ngIf="isProcessor && processorAddTask">
    <button id="btnAddtask" mat-flat-button [disabled]="(buttonDisableObservable | async)"
      class="mat-focus-indicator ig2 button button--tertiary manage-widget-button mat-button mat-button-base"
      (click)="addNewTask()">
      <img class=" button__icon " id="imgUser" src="/assets/icons/icon-plus-dark.svg " alt="plus-icon" /> Add New Task
    </button>
  </div>
  <div class="btnAddtask" *ngIf="!isProcessor && processorAddTask">
    <button id="btnAddtask" mat-flat-button
      class="mat-focus-indicator ig2 button button--tertiary manage-widget-button mat-button mat-button-base"
      (click)="addNewTask()">
      <img class=" button__icon " id="imgUser" src="/assets/icons/icon-plus-dark.svg " alt="plus-icon" /> Add New Task
    </button>
  </div>

</div>