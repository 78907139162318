<ag-grid-angular  #grid
  class="grid_style ag-theme-alpine"
  [headerHeight]="36"
  [rowHeight]="38"
  [rowSelection]="'single'"
  [rowModelType]="'infinite'"
  [enableCellTextSelection]="true"
  [defaultColDef]="defaultColumnDefs"
  [columnDefs]="columnDefs"
  [animateRows]="true"
  [cacheBlockSize]="pageSize"
  [cacheOverflowSize]="1"
  [maxConcurrentDatasourceRequests]="2"
  [infiniteInitialRowCount]="1"
  [maxBlocksInCache]="10"
  [getRowId]="getRowId"
  (selectionChanged)="onSelectionChanged()"
  (rowDoubleClicked)="onDoubleClicked($event)"
  (gridReady)="onGridReady()"
  (bodyScroll)="onScroll()">
</ag-grid-angular>
