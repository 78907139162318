import {Component} from '@angular/core';
import {ITooltipParams} from "ag-grid-community";
import {ITooltipAngularComp} from "ag-grid-angular";

@Component({
    selector: 'app-tooltip',
    template: `
      <div class="custom-tooltip">
          <span>{{ valueToDisplay }}</span>
      </div>`,
    styles: [
        `
          .custom-tooltip {
            padding: 12px;
            max-width: 396px;
            height: auto;
            text-wrap: wrap;
            overflow-wrap: break-word;

            border-radius: 2px;
            background: var(--color-neutral-gray-800, #323840);

            color: var(--color-neutral-white, #fff);

            /* MD (Default)/Regular -> 3 LH */
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
          }
        `
    ]
})
export class TooltipComponent implements ITooltipAngularComp {
  private params!: ITooltipParams;
  public valueToDisplay!: string;

  agInit(params: ITooltipParams): void {
    this.params = params;
    this.valueToDisplay = this.params.value;
  }
}