
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { EmailSummaryPopupComponent } from '../components/emailsummary-popup/emailsummary-popup.component';
import { ISearchEmailPayload } from '../models';
import { SLADateTime } from '../models/slaDateTime';
import { IEmailSummary } from '../models/task-summary.interface';

@Injectable({
    providedIn: 'root'
})
export class EmailDashboardService {



    readonly GTbaseUrl = `${environment.genericTaskBase.apiUrl}`;
    readonly DomainDataSearchbaseUrl = `${environment.domainDataSearchBase.apiUrl}`;
    readonly StaffingBaseUrl = `${environment.staffingBase.apiUrl}`;

    constructor(private httpClient: HttpClient, private matDialog: MatDialog) { }
    openEmailSummaryDialog(rowValue: any) {
        return this.matDialog.open(EmailSummaryPopupComponent, {
            panelClass: ['mat-dialog-container-large', 'ig2'],
            data: {
                rowValue
            },
            disableClose: true,
        });
    }
    getEmailsummary(emailId: any) {
        return this.httpClient.get(`${environment.igpsBase.apiUrl}/emailservice/dashboard/v1/emaildetail/${emailId}`)

    }
    previewDocument(id: any) {
        return this.httpClient.get(`${environment.igpsBase.apiUrl}/emailservice/dashboard/v1/emailattachmenturl/${id}`)
    }

    searchEmails(processId: string, payload: ISearchEmailPayload): Observable<{ totalItemCount: number, items: IEmailSummary[] }> {
      return this.httpClient.post<{
        totalItemCount: number,
        totalPageCount: number,
        pageNumber: number,
        count: number,
        pageSize: number,
        items: IEmailSummary[],
      }>(`${environment.igpsBase.apiUrl}/emailservice/email/v1/search/${processId}/${payload.statusId}/${payload.isSearchAllStatus}`,
        {
            search: payload.search,
            fromDate: payload.fromDate?.length! < 1 ? null : payload.fromDate,
            toDate: payload.toDate?.length! < 1 ? null : payload.toDate,
            pageRequest: payload.pageRequest,
            sortRequest: payload.sortRequest
        }
      ).pipe(
        map(pagedResponse => {
          return { totalItemCount: pagedResponse.totalItemCount, items: pagedResponse.items };
        }),
        map((res) => {
            const items = res.items.map(d => {
              d.slainsecs = ((new Date(d.dueDate).getTime() - new Date(d.orderDate).getTime()) / 1000).toString();
              d.timeToSLAinsecs = ((new Date(d.dueDate).getTime() - new Date().getTime()) / 1000).toString();
              const hhmmSLA = this.slaDateTime(d.timeToSLAinsecs);
              d.timeToSLA = hhmmSLA.getDays === "0" ? ` ${hhmmSLA.getHours}h ${hhmmSLA.getMinutes}m` :
              `${hhmmSLA.getDays}d ${hhmmSLA.getHours}h ${hhmmSLA.getMinutes}m`;
              d.timeToSLA = +d.timeToSLAinsecs > 0 ? d.timeToSLA: ("-" + d.timeToSLA);
              d.assignee = !d?.assigneeId ? "Unassigned" : d.assignee;
              d.assignee = d.assigneeSite ? d.assignee + '/' + d.assigneeSite : d.assignee;
              return d;
            });
            return { totalItemCount: res.totalItemCount, items };
          })
      );
    }

    slaDateTime(seconds: string): SLADateTime {
        // get total seconds between the times
        let delta = Math.abs(+seconds);

        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        return {
          getHours: hours.toString(),
          getMinutes: minutes<9? "0" + minutes : minutes.toString(),
          getDays: days.toString()
        }
      }
}