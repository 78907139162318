import { UtilityService } from './../../services/utility.service';
import { Component, EventEmitter, OnInit, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { BehaviorSubject, debounceTime, interval, map, Observable, Subscription } from 'rxjs';
import { cardSelectEvent, cardsEvent, resetTimerEvent, searchInputEvent } from '../../../app/services/EventEmitters';
import { UserInfo } from '../../../app/models';
import { getUserInfo, getTimezone, timezone$ } from '@igps/client-root-config';
import { GetSelectedProcess, onChangeProcess } from '@igps/top-utility';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
let cardcount!: { id: number; cardName: string; count: number; }[];
export const getCardCount: () => { id: number; cardName: string; count: number; }[] = () => cardcount
@Component({
  selector: 'app-task-dashboard-grid',
  templateUrl: './dashboard-grid.component.html',
  styleUrls: ['./dashboard-grid.component.scss']
})
export class DashboardGridComponent implements OnInit, OnDestroy {

  search: EventEmitter<any> = new EventEmitter<any>();
  showGridSpinner: boolean = false;
  isProcessor!: boolean;
  userInfo!: UserInfo;
  onChangeSubscription!: Subscription;
  gridSpinnerSubscription!: Subscription;
  processId!: string;
  processorAddTask: boolean = false;
  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  refreshGridData: boolean = false;
  enableDiasbleAddNewTask!: boolean;
  buttonDisableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  buttonDisableObservable: Observable<boolean> = this.buttonDisableSubject.asObservable();
  isUserAlreadyAissngedWithTask!: boolean;
  isTaskAvailable!: boolean;
  isMyTaskAvailable!: boolean;
  noNewTask!: boolean;
  inProgress: any;
  getCardsCountIntervalSubscription!: Subscription;
  cardsCountEventSubscription!: Subscription;

  constructor(private ngZone: NgZone, private utilityService: UtilityService, private cdref: ChangeDetectorRef, private router: Router, private service: DashboardService) { }

  ngOnDestroy(): void {
    this.cardSelectEventSubscription?.unsubscribe();
    this.searchEventSubscription?.unsubscribe();
    this.onChangeSubscription?.unsubscribe();
    this.gridSpinnerSubscription?.unsubscribe();
    this.timezoneSubscription?.unsubscribe();
    this.cardsCountEventSubscription?.unsubscribe();
    this.getCardsCountIntervalSubscription?.unsubscribe();
  }

  DashboardSelectedCard!: { card: { id: number; cardName: string; count: number; } | null, processId: string };
  cardSelectEventSubscription!: Subscription;
  searchEventSubscription!: Subscription;

  updatedTime!: string;
  searchText: string = "";

  ngOnInit(): void {

    this.cardSelectEventSubscription = cardSelectEvent.subscribe(d => {
      this.DashboardSelectedCard = d;
      if (this.DashboardSelectedCard.card) {
        this.searchText = "";
      }
    });
    this.gridSpinnerSubscription = this.utilityService.showGridSpinner.subscribe(d => {
      this.showGridSpinner = d;
      this.cdref.detectChanges();
    })
    this.searchEventSubscription = this.search.asObservable().pipe(
      map(d => {
        resetTimerEvent.emit();
        return d.target.value as string;
      }),
      debounceTime(500)
    ).subscribe(d => {
      cardSelectEvent.emit({ card: null, processId: this.processId, isAutoRefresh: false });
      searchInputEvent.emit(d.trim());
    });
    this.initializeUserRole();
    this.onChangeSubscription = onChangeProcess.subscribe((process: {
      taskSource: any; id: string; name: string; processorAddTask: boolean
    }) => {
      this.ngZone.run(() => {
        this.processId = process.id;
        this.processorAddTask = process.processorAddTask
        this.getUserAssignedStatusToTask();
        this.cdref.detectChanges();
      });
    });

    if (!this.processId) {
      this.ngZone.run(() => {
        this.service.getprocessdetails(sessionStorage.getItem('ActiveProcessId')).subscribe((res: any) => {
          if (res) {
            this.processId = res.id;
            this.processorAddTask = res.processorAddTask;
            this.getUserAssignedStatusToTask();
            this.cdref.detectChanges();
          }
        }) //It was commented earlier - Bug#316671
      })

    }
    const intervalSubscription = interval(200).subscribe(() => {
      if (cardsEvent) {
        this.cardsCountEventSubscription = cardsEvent.subscribe(cc => {
          this.ngZone.run(() => {
            cardcount = cc
            this.isTaskAvailable = cc.find(c => c.id === 1)!.count > 0 ? true : false;
            this.inProgress = getCardCount().find(c => c.id === 2)!.count > 0 || getCardCount().find(c => c.id === 3)!.count > 0 || getCardCount().find(c => c.id === 4)!.count > 0 ? true : false;
            this.isUserAlreadyAissngedWithTask = (getCardCount().find(c => c.id === 0)!.count > 0 && (getCardCount().find(c => c.id === 5)!.count !== getCardCount().find(c => c.id === 0)!.count)) ? true : false; // checking hold status
            if (!this.isTaskAvailable && !this.isUserAlreadyAissngedWithTask)
              this.enableDiasbleAddNewTask = false
            else
              this.enableDiasbleAddNewTask = (this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable);

            this.buttonDisableSubject.next(this.enableDiasbleAddNewTask);
          });
        });
        intervalSubscription.unsubscribe();
      }
    })
    this.getCardsCountIntervalSubscription = interval(1000).subscribe(() => {
      this.checkCardsCount();
    });
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
    });
  }

  getUserAssignedStatusToTask() {
    this.ngZone.run(() => {
      if (this.processId !== undefined) {
        this.service.getStatusTaskAssignedToUser(this.processId).subscribe((res: any) => {
          this.isUserAlreadyAissngedWithTask = res?.isAssigned;
          this.buttonDisableSubject.next((this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable));
          this.cdref.detectChanges();
        });
      }
    });
  }

  checkCardsCount() {
    this.ngZone.run(() => {
      if (getCardCount()) {
        this.isTaskAvailable = getCardCount().find(c => c.id === 1)!.count > 0 ? true : false;
        this.isUserAlreadyAissngedWithTask = (getCardCount().find(c => c.id === 0)!.count > 0 && (getCardCount().find(c => c.id === 5)!.count !== getCardCount().find(c => c.id === 0)!.count)) ? true : false; // checking hold status
        if (!this.isTaskAvailable && !this.isUserAlreadyAissngedWithTask)
          this.enableDiasbleAddNewTask = false
        else
          this.enableDiasbleAddNewTask = (this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable);

        this.buttonDisableSubject.next(this.enableDiasbleAddNewTask);
        this.cdref.detectChanges();

      }
    });
  }
  clearSearch() {
    this.searchText = "";
    searchInputEvent.emit(this.searchText);
  }

  private initializeUserRole() {
    this.userInfo = getUserInfo();
    if (this.userInfo.assumedRoleId === 2) {
      this.isProcessor = true;
    } else {
      this.isProcessor = false;
    }
  }
  updateTime
    () {
    this.updatedTime = new Date().toUTCString();
  }
  addNewTask() {
    this.router.navigate(['/file-search']);
  }
  refreshGrid() {
    this.refreshGridData = !this.refreshGridData;
  }
}
