import { GetSelectedProcess, onChangeProcess } from '@igps/top-utility';
import { UtilityService } from './services/utility.service';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faError, faMagnifyingGlass, faPencilMessage, faDocumentView, faFileDoc, faFileDocx, faFileJpeg, faFileJpg, faFilePdf, faFilePng, faFileTif, faFileTiff, faFileXls, faFileXlsx, FaIconsRegistry, faKebab, faMagnifyingGlassPlus, faMail, faTrash, faPlusCircle, faRefresh } from '@ui-core/icon';
import { ToastComponent } from '@ui-core/toast';
import { Subscription } from 'rxjs';

@Component({
  selector: 'igps-dashboard-grid',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IGPS-Client-Dashboard-Grid-UI';

  toastEventSubscription!: Subscription;
  spinnerSubscription!: Subscription;

  @ViewChild(ToastComponent) toaster!: ToastComponent;
  showSpinner: boolean = false;

  taskSource!: number;
  onChangeSubscription!: Subscription;

  constructor(private faIconsRegistry: FaIconsRegistry, private utilityService: UtilityService, private cdref: ChangeDetectorRef) {
    this.faIconsRegistry.registerIcons([faError, faMagnifyingGlass, faPencilMessage, faFileDoc, faFileDocx, faFileJpeg, faFileJpg, faFilePdf, faFilePng, faFileTif, faFileTiff, faFileXls, faFileXlsx, faMail, faKebab, faTrash, faDocumentView, faMagnifyingGlassPlus, faPlusCircle, faRefresh]);
  }

  ngOnInit(): void {
    this.spinnerSubscription = this.utilityService.showSpinner.subscribe(d => { this.showSpinner = d; this.cdref.detectChanges(); });
    this.toastEventSubscription = this.utilityService.showToast.subscribe(d => { this.openToast(d.message, d.isError); this.cdref.detectChanges(); });
    const process = GetSelectedProcess();
    if (process) {
      this.taskSource = process.taskSource;
      sessionStorage.setItem('taskSource', this.taskSource.toString())
      this.cdref.detectChanges();
    }
    this.onChangeSubscription = onChangeProcess.subscribe((process) => {
      this.taskSource = process.taskSource;
      sessionStorage.setItem('taskSource', this.taskSource.toString())
      this.cdref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.toastEventSubscription.unsubscribe();
    this.spinnerSubscription.unsubscribe();
    this.onChangeSubscription.unsubscribe();
  }

  openToast(message: string, isError: boolean = false) {
    this.toaster.toastMessage = message;
    this.toaster.actionRightText = 'close';
    this.toaster.showToast = true;
    this.toaster.error = isError;
    this.toaster.toasterTimeout();
    setTimeout(() => {
      this.cdref.detectChanges();
    }, 5100);
  }

}
