import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailDashboardService } from '../../services/emaildashboard.service';
import { UtilityService } from '../../services/utility.service';
import { Subscription, finalize } from 'rxjs';
import { getTimezone, timezone$ } from '@igps/client-root-config'
import { ConvertTimePipe } from '@igps/pipe';

@Component({
    selector: 'app-emailsummary-popup',
    templateUrl: './emailsummary-popup.component.html',
    styleUrls: ['./emailsummary-popup.component.scss']
})
export class EmailSummaryPopupComponent implements OnInit, OnDestroy {
    timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
    emailDetails: any = null
    isProfilePhotoAvailable: boolean = true;
    timezoneSubscription!: Subscription;
    constructor(public dialogRef: MatDialogRef<EmailSummaryPopupComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any, public emaildashservice: EmailDashboardService, public utilityservice: UtilityService, private datePipe: ConvertTimePipe) {

    }

    ngOnDestroy(): void {
        this.timezoneSubscription.unsubscribe();
    }
    ngOnInit(): void {
        this.timezone = getTimezone();
        this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
            this.timezone = timezone;
        })
        this.utilityservice.setSpinner(true);
        this.emaildashservice.getEmailsummary(this.data.rowValue.id).subscribe(res => {

            if (res) {
                this.utilityservice.setSpinner(false);
                this.emailDetails = res;
            }
        }, err => {
            this.utilityservice.setSpinner(false);
        })

    }
    previewAttachment(attachment: any) {
        this.utilityservice.setSpinner(true);
        this.emaildashservice.previewDocument(attachment.id).pipe(
            finalize(() => {
                this.utilityservice.setSpinner(false);
            })
        ).subscribe((res: any) => {
            // if (res && res.bytes) {
            if (res) {
                if (attachment.contentType == 'application/pdf') {
                    // var blob = this.b64toBlob(res.bytes, "application/pdf");
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    const url = res.url;
                    window.open(url, '_blank')

                }
                else {
                    if (attachment.contentType == 'image/tiff' || attachment.contentType == 'image/jpeg' || attachment.contentType == 'image/png') {
                        this.downloadImage(res.url, attachment.name);
                    } else {
                        const a = document.createElement("a");
                        a.href = res.url;
                        a.download = attachment.name;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                }
            }
        })
    }

    formatBytes(bytes: any, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    onProfilePhotoLoadError() {
        this.isProfilePhotoAvailable = false;
    }

    getInitials(name: string): string {
        let initial = "";
        let fullName = name.split(":")[0];
        fullName.split(" ").forEach(n => {
            if (n?.length > 0) {
                initial += n[0].toUpperCase();
            }
        })
        return initial;
    }
    async downloadImage(imageSrc: any, nameOfDownload: any) {
        const response = await fetch(imageSrc);

        const blobImage = await response.blob();

        const href = URL.createObjectURL(blobImage);

        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        anchorElement.download = nameOfDownload;

        document.body.appendChild(anchorElement);
        anchorElement.click();

        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
    }


}
