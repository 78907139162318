import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { getTimezone, timezone$ } from '@igps/client-root-config';
import { Subscription, debounceTime, map } from 'rxjs';
import { ICardModel, ISearchEmailPayload, ISearchToggleStatus } from '../../models';
import { cardSelectEvent, resetTimerEvent, searchEmailInputEvent } from '../../services/EventEmitters';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-email-dashboard-grid',
  templateUrl: './email-dashboard-grid.component.html',
  styleUrls: ['./email-dashboard-grid.component.scss']
})
export class EmailDashboardGridComponent implements OnInit, OnDestroy {
  showGridSpinner: boolean = false;
  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  updatedTime!: string;
  refreshGridData: boolean = false;

  searchText: string = "";
  receivedDateFrom: string = "";
  receivedDateTo: string = "";
  searchEvent: EventEmitter<any> = new EventEmitter<any>();
  isSearchAllStatus: boolean = false;
  searchEventSubscription!: Subscription;
  cardSelectEventSubscription!: Subscription;
  toggleText = "Statuses";
  maxDate!: Date;
  selectedCard!: ICardModel | null;
  processId!: string;
  toggleStatusKey = 'cardSearchToggleStatus';
  isDateFromValid = false;
  isDateToValid = false;

  constructor(private cdRef: ChangeDetectorRef, private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
    });
    this.maxDate = new Date();

    this.searchEventSubscription = this.searchEvent.asObservable().pipe(
      map(d => {
        resetTimerEvent.emit();
        return d.target.value as string;
      }),
      debounceTime(500)
    ).subscribe(d => {
      if (d.trim().length !== 0) {
        searchEmailInputEvent.emit(this.getPayload());
      } else {
        if (this.searchText === "" && this.receivedDateFrom === "" && this.receivedDateTo === "") {
          this.refreshGridData = !this.refreshGridData;
          cardSelectEvent.emit({ card: this.selectedCard, processId: this.processId, isAutoRefresh: false });
        } else {
          searchEmailInputEvent.emit(this.getPayload());
        }

      }
    });

    this.cardSelectEventSubscription = cardSelectEvent.subscribe((res) => {
      this.selectedCard = res.card;
      this.processId = res.processId;

      if (this.selectedCard?.id === 0) {
        this.toggleText = "My Tasks"

      }
      else if (this.selectedCard?.id === 11) {
        this.toggleText = "Mailboxes"
      }
      else {
        this.toggleText = "Statuses"
      }

      this.getToggleStatuses();
      this.clearSearchInputs();
    });

  }

  updateTime() {
    this.updatedTime = new Date().toUTCString();
  }

  ngOnDestroy(): void {
    this.timezoneSubscription?.unsubscribe();
    this.cardSelectEventSubscription?.unsubscribe();
    this.searchEventSubscription?.unsubscribe();
  }

  refreshGrid() {
    const key = this.toggleStatusKey;
    sessionStorage.removeItem(key);
    this.isSearchAllStatus = false;
    this.refreshGridData = !this.refreshGridData;
    this.clearSearchInputs();
  }

  clearSearch() {
    this.searchText = "";
    if (this.searchText === "" && this.receivedDateFrom === "" && this.receivedDateTo === "") {
      this.refreshGridData = !this.refreshGridData;
      cardSelectEvent.emit({ card: this.selectedCard, processId: this.processId, isAutoRefresh: false });
    } else {
      searchEmailInputEvent.emit(this.getPayload());
    }
  }

  clearSearchInputs() {
    this.searchText = "";
    this.receivedDateFrom = "";
    this.receivedDateTo = "";
    this.isDateFromValid = false;
    this.isDateToValid = false;
  }

  onClickSearch() {
    if (this.searchText === "" && this.receivedDateFrom === "" && this.receivedDateTo === "") {
      this.refreshGridData = !this.refreshGridData;
    } else {
      searchEmailInputEvent.emit(this.getPayload());
    }
  }

  getPayload(): ISearchEmailPayload {
    return {
      search: this.searchText.replace(/ +(?= )/g, ''),
      fromDate: this.receivedDateFrom,
      toDate: this.receivedDateTo,
      statusId: this.selectedCard?.id === undefined ? 0 : this.selectedCard?.id,
      isSearchAllStatus: this.isSearchAllStatus,
      pageRequest: {
        PageNumber: 1,
        PageSize: 20
      },
      sortRequest: null
    };
  }

  onToggle() {
    this.cdRef.detectChanges();
    this.setToggleStatuses();

    if (this.searchText.trim().length !== 0 || this.receivedDateFrom.length !== 0 || this.receivedDateTo.length !== 0) {
      searchEmailInputEvent.emit(this.getPayload());
    }
  }

  onChangeDate = () => {
    this.isDateFromValid = !isNaN(Date.parse(this.receivedDateFrom));
    this.isDateToValid = !isNaN(Date.parse(this.receivedDateTo));
    searchEmailInputEvent.emit(this.getPayload());
  };

  getToggleStatuses() {
    this.isSearchAllStatus = false;
    const key = this.toggleStatusKey;
    const localValue = sessionStorage.getItem(key);
    if (localValue !== null) {
      const data = JSON.parse(localValue) as ISearchToggleStatus[];
      data.forEach((item: ISearchToggleStatus) => {
        if (item.cardId === this.selectedCard?.id) {
          this.isSearchAllStatus = item.isSearchAllStatus;
        }
      });
    }
  }

  setToggleStatuses() {
    const key = this.toggleStatusKey;
    const localValue = JSON.parse(sessionStorage.getItem(key)!) as ISearchToggleStatus[] ?? [];
    let item!: ISearchToggleStatus;
    if (localValue.length > 0 && localValue.some(y => y.cardId === this.selectedCard?.id)) {
      localValue.forEach((x: ISearchToggleStatus) => {
        if (x.cardId === this.selectedCard?.id) {
          x.cardId = this.selectedCard?.id;
          x.cardName = this.selectedCard?.cardName;
          x.isSearchAllStatus = this.isSearchAllStatus;
        }
      });
    }
    else {
      item = { cardId: this.selectedCard?.id!, cardName: this.selectedCard?.cardName!, isSearchAllStatus: this.isSearchAllStatus };
      localValue.push(item);
    }
    sessionStorage.setItem(key, JSON.stringify(localValue));
  }

  clearFromDate() {
    this.receivedDateFrom = "";
    this.isDateFromValid = false;
    this.triggerEvent();
  }

  clearToDate() {
    this.receivedDateTo = "";
    this.isDateToValid = false;
    this.triggerEvent();
  }

  private triggerEvent() {
    if (this.searchText === "" && this.receivedDateFrom === "" && this.receivedDateTo === "") {
      cardSelectEvent.emit({ card: this.selectedCard, processId: this.processId, isAutoRefresh: false });
    }
    else {
      searchEmailInputEvent.emit(this.getPayload());
    }
  }
}

