import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { getUserInfo, ipsWsMessages$ } from '@igps/client-root-config';
import { GetSelectedProcess, onChangeProcess, selectedTaskId } from '@igps/top-utility';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColumnApi, GetRowIdFunc, GetRowIdParams, GridApi, IDatasource, IGetRowsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { Subscription, finalize } from 'rxjs';
import { ProcessorTaskSummaryDataModel, ProcessorTaskSummarySearchDataModel, UserInfo } from '../../../app/models';
import { Events } from '../../../app/models/Events';
import { IMessage } from '../../../app/models/wsmessage';
import { cardSelectEvent, resetTimerEvent, searchInputEvent } from '../../../app/services/EventEmitters';
import { DashboardService } from '../../../app/services/dashboard.service';
import { ReorderCellRendererComponent } from '../reorder-cell-renderer/reorder-cell-renderer.component';
import { UtilityService } from './../../services/utility.service';
import { NotesCellRendererComponent } from './../notes-cell-renderer/notes-cell-renderer.component';

@Component({
  selector: 'app-processor-grid',
  templateUrl: './processor-grid.component.html',
  styleUrls: ['./processor-grid.component.scss']
})
export class ProcessorGridComponent implements OnInit, OnDestroy {

  @Output() updateTime: EventEmitter<any> = new EventEmitter<any>();

  columnDefs: ColDef[] = [];
  taskSummarySearchedData!: ProcessorTaskSummarySearchDataModel;

  @ViewChild("grid", { static: false }) grid!: AgGridAngular;
  gridApi!: GridApi;
  gridColumnApi!: ColumnApi;

  cellRendererComponents = {
    notes: NotesCellRendererComponent,
    reorder: ReorderCellRendererComponent
  }

  selectedRow!: ProcessorTaskSummaryDataModel | null;

  reloadIntervalSubscription!: Subscription;
  resetTimerSubscription!: Subscription;
  onSelectedProcessChangeSubscription!: Subscription;
  searchEventSubscription!: Subscription;
  WebSockectSubscription!: Subscription;
  selectedProcessId!: string;
  userInfo!: UserInfo;
  searchText: string = "";
  pageSize: number = 20;
  refreshIntervalInSeconds: number = 120;
  cardSelectEventSubscription!: Subscription;
  private resizeListenerFunc = () => { this.gridApi.sizeColumnsToFit(); };

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };
  DashboardSelectedCard!: { card: { id: number; cardName: string; count: number; } | null; processId: string; isAutoRefresh: boolean; };

  constructor(
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
    private router: Router
  ) {
    this.subscribeEventEmitter();
    this.initiazeGrid();
  }

  ngOnInit(): void {
    selectedTaskId.emit(null);
    this.userInfo = getUserInfo();
    this.cardSelectEventSubscription = cardSelectEvent.subscribe(d => {
      this.DashboardSelectedCard = d;
      if (!d.isAutoRefresh) {
        if (d.card != null) {
          this.loadTaskSummary();
        }
        else if (this.searchText.length !== 0) {
          this.getSearchData();
        }
      }
    });
    this.searchEventSubscription = searchInputEvent.subscribe(d => {
      this.searchText = d.trim();
      if (d.trim().length !== 0) {
        this.getSearchData();
      }
    });
    this.WebSockectSubscription = ipsWsMessages$.subscribe((message: any) => {
      const data: IMessage = message as IMessage;
      if (Events.TriggerEvents.includes(data?.EventName?.toLowerCase())) {
        if (data?.Payload?.TaskId === this.selectedRow?.id) {
          this.onSelectionChanged();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.onSelectedProcessChangeSubscription.unsubscribe();
    this.searchEventSubscription.unsubscribe();
    this.WebSockectSubscription.unsubscribe();
    window.removeEventListener('resize', this.resizeListenerFunc);
    window.removeEventListener('click', this.resizeListenerFunc);
    // removeEventListener('focus', this.onfocus);
    // removeEventListener('blur', this.onblur);
  }

  defaultColumnDefs: ColDef = {
    suppressNavigable: true,
    editable: false,
    cellClass: 'no-border'
  }

  // onfocus = () => {
  //   this.removeReloadAndResetSubscriptions();
  //   this.addReloadIntervalSubscription();
  //   this.addResetTimerSubscription();
  // };
  // onblur = () => {
  //   this.removeReloadAndResetSubscriptions();
  // };

  onGridReady() {
    this.gridApi = this.grid.api;
    this.gridColumnApi = this.grid.columnApi;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener('resize', this.resizeListenerFunc);
    window.addEventListener('click', this.resizeListenerFunc);
    this.loadTaskSummary();
  }

  initiazeGrid() {
    this.columnDefs = [
      { headerName: 'Id', field: 'id', hide: true },
      { headerName: 'File Number', field: 'fileNumber', sortable: true, tooltipField: "fileNumber", minWidth: 30, maxWidth: 170 },
      { headerName: 'Notes', field: 'totalNotesComments', cellRenderer: NotesCellRendererComponent, minWidth: 30, maxWidth: 100 },
      { headerName: 'Task Type', field: 'taskType', sortable: true, tooltipField: "taskType", minWidth: 20, maxWidth: 170 },
      { headerName: 'Time To SLA', field: 'slaInSeconds', sortable: true, tooltipField: "slaInSeconds", minWidth: 20, maxWidth: 170 },
      { headerName: 'Status', field: 'status', sortable: true, tooltipField: "status", minWidth: 20, maxWidth: 170 },
      { headerName: 'Office', field: 'office', sortable: true, tooltipField: "office", minWidth: 20 },
      { headerName: 'Officer', field: 'officer', sortable: true, tooltipField: "officer", minWidth: 20 },
      // {
      //   headerName: '', field: '', cellRenderer: ReorderCellRendererComponent, maxWidth: 110, minWidth: 90, cellRendererParams: {
      //     getProcessId: () => this.selectedProcessId
      //   } as ReorderParams
      // }
    ];

  }

  async loadTaskSummary() {
    if (this.DashboardSelectedCard?.card!) {
      this.utilityService.setSpinner(true);
      let lastRow: number = -1;
      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: (params: IGetRowsParams) => {
          const startRow: number = params.startRow;
          const endRow: number = params.endRow;
          let sort: { Field: string, Direction: 1 | 2 } | null = null;
          if (params.sortModel.length > 0) {
            sort = {
              Field: params.sortModel[0].colId === 'timeToSLA' ? 'slaInSeconds' : params.sortModel[0].colId,
              Direction: params.sortModel[0].sort === 'asc' ? 1 : 2
            };
          }
          this.dashboardService.getTasks(this.userInfo.assumedRoleId, this.DashboardSelectedCard.processId, (this.DashboardSelectedCard.card as { id: number; cardName: string; count: number; }).id, (endRow / this.pageSize), this.pageSize, sort).pipe(
            finalize(() => {
              this.utilityService.setSpinner(false);
            })
          ).subscribe({
            next: (d) => {
              selectedTaskId.emit(null);
              this.updateTime.emit();
              this.afterDataChange();
              if (params.endRow >= d.totalItemCount) {
                lastRow = d.totalItemCount;
              }
              params.successCallback(d.items, lastRow);
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.Type === "HttpRequestException") {
                this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
              }
              else {
                this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
              }
            }
          })
        },
      };
      this.grid.api!.setDatasource(dataSource);
    }
  }
  getSearchData() {
    if (this.searchText.trim().length !== 0) {
      this.utilityService.setGridSpinner(true);
      let lastRow: number = -1;
      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: (params: IGetRowsParams) => {
          const startRow: number = params.startRow;
          const endRow: number = params.endRow;
          let sort: { Field: string, Direction: 1 | 2 } | null = null;
          if (params.sortModel.length > 0) {
            sort = {
              Field: params.sortModel[0].colId === 'timeToSLA' ? 'slaInSeconds' : params.sortModel[0].colId,
              Direction: params.sortModel[0].sort === 'asc' ? 1 : 2
            };
          }
          this.dashboardService.searchTasks(this.selectedProcessId, this.searchText.trim(), (endRow / this.pageSize), this.pageSize, sort).pipe(
            finalize(() => {
              this.utilityService.setGridSpinner(false);
            })
          ).subscribe({
            next: (d) => {
              selectedTaskId.emit(null);
              this.updateTime.emit();
              this.afterDataChange();
              if (params.endRow >= d.totalItemCount) {
                lastRow = d.totalItemCount;
              }
              params.successCallback(d.items, lastRow);
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.Type === "HttpRequestException") {
                this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
              }
              else {
                this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
              }
            }
          })
        },
      };
      this.grid.api!.setDatasource(dataSource);
    }
  }

  onSelectionChanged() {
    const selectedRowFromEvent = this.gridApi.getSelectedRows();
    this.selectedRow = null;
    if (selectedRowFromEvent.length > 0) {
      this.selectedRow = selectedRowFromEvent[0];
    }
    if (this.selectedRow) {
      selectedTaskId.emit(this.selectedRow.id);
      // notesClickEvent.emit(this.selectedRow.id);
    }
    else {
      selectedTaskId.emit(null);
    }
    this.gridApi.sizeColumnsToFit();
  }

  onDoubleClicked(event: RowDoubleClickedEvent) {
    const selectedRowFromEvent: ProcessorTaskSummaryDataModel = event.data as ProcessorTaskSummaryDataModel;
    this.router.navigate(['/process/task', selectedRowFromEvent.id]);
  }

  onScroll() {
    resetTimerEvent.emit();
  }

  afterDataChange() {
    this.gridApi.deselectAll();
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  // private addReloadIntervalSubscription(){
  //   this.reloadIntervalSubscription = interval(this.refreshIntervalInSeconds * 1000).subscribe(() => {
  //     if (this.searchText.length !== 0) {
  //       this.getSearchData();
  //     }
  //     else {
  //       this.loadTaskSummary();
  //     }
  //   });
  // }

  // private addResetTimerSubscription(){
  //   this.resetTimerSubscription = resetTimerEvent.asObservable().pipe(debounceTime(1000)).subscribe(() => {
  //     if (this.reloadIntervalSubscription) {
  //       this.reloadIntervalSubscription.unsubscribe();
  //       this.reloadIntervalSubscription = interval(this.refreshIntervalInSeconds * 1000).subscribe(() => {
  //         if (this.searchText.length !== 0) {
  //           this.getSearchData();
  //         }
  //         else {
  //           this.loadTaskSummary();
  //         }
  //       });
  //     }
  //   });
  // }

  // private removeReloadAndResetSubscriptions(){
  //   if (this.reloadIntervalSubscription) {
  //     this.reloadIntervalSubscription.unsubscribe();
  //   }
  //   if (this.resetTimerSubscription) {
  //     this.resetTimerSubscription.unsubscribe();
  //   }
  // }

  subscribeEventEmitter() {
    this.onSelectedProcessChangeSubscription = onChangeProcess.subscribe((process) => {
      this.selectedProcessId = process.id;
      this.loadTaskSummary();
    });
    if (!this.selectedProcessId) {
      const process = GetSelectedProcess();
      if (process?.id) {
        this.selectedProcessId = process.id;
        this.loadTaskSummary();
      }
    }
    this.searchEventSubscription = searchInputEvent.subscribe(d => {
      this.searchText = d.trim();
      if (d.trim().length !== 0) {
        this.getSearchData();
      }
      else {
        this.loadTaskSummary();
      }
    });
    this.WebSockectSubscription = ipsWsMessages$.subscribe((message: any) => {
      const data: IMessage = message as IMessage;
      if (Events.TriggerEvents.includes(data?.EventName?.toLowerCase())) {
        if (data?.Payload?.TaskId === this.selectedRow?.id) {
          this.onSelectionChanged();
        }
      }
    });
  }

}
