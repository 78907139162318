import { EventEmitter } from '@angular/core';
import { ProcessDataModel } from '../models/processDataModel';
import { ISearchEmailPayload } from '../models';

export const cardSelectEvent: EventEmitter<{ card: { id: number; cardName: string; count: number; } | null, processId: string, isAutoRefresh: boolean }> = new EventEmitter<{ card: { id: number; cardName: string; count: number; } | null, processId: string, isAutoRefresh: boolean }>();
export const selectedProcessEvent: EventEmitter<ProcessDataModel> = new EventEmitter<ProcessDataModel>();
export const searchInputEvent: EventEmitter<string> = new EventEmitter<string>();
export const reloadEvent: EventEmitter<void> = new EventEmitter<void>();
export const resetTimerEvent: EventEmitter<void> = new EventEmitter<void>();
export const searchEmailInputEvent: EventEmitter<ISearchEmailPayload> = new EventEmitter<ISearchEmailPayload>();
export const cardsEvent: EventEmitter<{ id: number; cardName: string; count: number; }[]> = new EventEmitter<{ id: number; cardName: string; count: number; }[]>();
