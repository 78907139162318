(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@igps/client-root-config"), require("@igps/top-utility"));
	else if(typeof define === 'function' && define.amd)
		define(["@igps/client-root-config", "@igps/top-utility"], factory);
	else if(typeof exports === 'object')
		exports["IGPS-Client-Dashboard-Grid-UI"] = factory(require("@igps/client-root-config"), require("@igps/top-utility"));
	else
		root["IGPS-Client-Dashboard-Grid-UI"] = factory(root["@igps/client-root-config"], root["@igps/top-utility"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__141__, __WEBPACK_EXTERNAL_MODULE__60__) => {
return 