<article class="dialog__header mat-dialog-title">
    <h4 mat-dialog-title *ngIf="emailDetails?.fileNumber">{{emailDetails?.fileNumber}} | {{emailDetails?.subject}}</h4>
    <h4 mat-dialog-title *ngIf="!emailDetails?.fileNumber">{{emailDetails?.subject}}</h4>
    <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
</article>
<mat-dialog-content class="mat-typography mat-dialog-content emailpopup">
    <div class="email-box">
        <div class="email-sub-box">
            <div class="headertext subject">
                <div class="common-notes-card--header--avatar ng-star-inserted"
                    *ngIf="emailDetails?.profilePhoto && isProfilePhotoAvailable">
                    <img [src]=emailDetails.profilePhoto (error)="onProfilePhotoLoadError()" alt="">
                </div>
                <div class="create-user-initials-text-icon" *ngIf="!isProfilePhotoAvailable">
                    <span>{{getInitials(emailDetails.from)}}</span>
                </div>

                From: {{emailDetails?.from}}

            </div>
            <div class="headertext subject">To: {{emailDetails?.to}}</div>
            <div class="headertext subject">Subject: {{emailDetails?.subject}}</div>
            <div class="headertext subject">Received: {{emailDetails.receivedDate | convertTime: 'utc' : timezone.value
                }}</div>
        </div>

        <div class="email-content" [innerHTML]="emailDetails.body |safeHtml" [ngClass]="emailDetails.attachments.length <=0 ? 'email-content-with-attachment':'email-content' ">

        </div>
        <div class="footerlayout" *ngIf="emailDetails.attachments.length>0">
            <div class="doc-name" *ngFor="let x of emailDetails.attachments" (click)="previewAttachment(x)">
                <div class="docnameList">
                    <div class="icon">
                        <lib-icon *ngIf="x.contentType!==''"
                            [name]="'file_'+ x.name.split('.')[x.name.split('.').length - 1].toLowerCase()">
                        </lib-icon>
                        <lib-icon name="mail" *ngIf="x.contentType==''">
                        </lib-icon>
                    </div>
                    <div class="name">
                        <p>{{x.name}}</p>
                        <p class="ptext">{{formatBytes(x.size)}}</p>
                    </div>
                </div>

                <div class="icon preview zoomicon">
                    <lib-icon name="magnifying_glass_plus">
                    </lib-icon>
                </div>
            </div>

        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions" align="end">
    <button id="btnSave" mat-flat-button mat-dialog-close
        class="mat-focus-indicator ig2 button button--primary button--small mat-button mat-button-base mat-flat-button"><span
            class="mat-button-wrapper">
            Close
        </span></button>
</mat-dialog-actions>