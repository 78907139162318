import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardGridComponent } from './components/dashboard-grid/dashboard-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { ManagerGridComponent } from './components/manager-grid/manager-grid.component';
import { ProcessorGridComponent } from './components/processor-grid/processor-grid.component';
import { AssigneeCellRendererComponent } from './components/assignee-cell-renderer/assignee-cell-renderer.component';
import { NotesCellRendererComponent } from './components/notes-cell-renderer/notes-cell-renderer.component';
import { ReorderCellRendererComponent } from './components/reorder-cell-renderer/reorder-cell-renderer.component';
import { ReAssignPopupComponent } from './components/re-assign-popup/re-assign-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { HttpInterceptor } from '../app/interceptor/header-interceptor.interceptor';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FilenumberCellRendererComponent } from './components/filenumber-cell-renderer/filenumber-cell-renderer.component';
import { PipeModule, ConvertTimePipe } from '@igps/pipe';
import { SpinnerModule } from '@igps/spinner';
import { ToastModule } from '@ui-core/toast';
import { FilterSelectModule } from '@igps/filter-select';
import { EmailDashboardGridComponent } from './components/email-dashboard-grid/email-dashboard-grid.component';
import { EmailManagerGridComponent } from './components/email-manager-grid/email-manager-grid.component';
import { EmailCellRendererComponent } from './components/email-cell-renderer/email-cell-renderer.component';
import { EmailSummaryPopupComponent } from './components/emailsummary-popup/emailsummary-popup.component';
import { SafeHtmlPipe } from './shared/pipes/safe.html';
import { SubjectCellRendererComponent } from './components/subject-cell-renderer/subject-cell-renderer.component';
import { ReAssignPopupEmailComponent } from './components/re-assign-popup-email/re-assign-popup-email.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IconModule } from '@ui-core/icon';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    DashboardGridComponent,
    ManagerGridComponent,
    ProcessorGridComponent,
    AssigneeCellRendererComponent,
    NotesCellRendererComponent,
    ReorderCellRendererComponent,
    ReAssignPopupComponent,
    ReorderCellRendererComponent,
    FilenumberCellRendererComponent,
    SubjectCellRendererComponent,
    EmailDashboardGridComponent,
    EmailManagerGridComponent,
    EmailSummaryPopupComponent,
    SafeHtmlPipe,
    EmailCellRendererComponent,
    ReAssignPopupEmailComponent,
    TooltipComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    AgGridModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    AppRoutingModule,
    IconModule,
    PipeModule,
    SpinnerModule,
    ToastModule,
    FilterSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: HttpInterceptor
    },
    DatePipe,
    ConvertTimePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
