import { Component } from '@angular/core';
import { getUserInfo } from '@igps/client-root-config';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { finalize } from 'rxjs';
import { UserInfo } from '../../../app/models';
import { IEmailSummary, ITaskSummary } from '../../../app/models/task-summary.interface';
import { reloadEvent } from '../../../app/services/EventEmitters';
import { DashboardService } from '../../../app/services/dashboard.service';
import { UtilityService } from './../../services/utility.service';

export interface ReorderParams {
  getProcessId: () => string;
  isEmailDashboard: () => boolean;
}

@Component({
  selector: 'app-reorder-cell-renderer',
  template: `
     <ng-container *ngIf="params.data !== undefined || userInfo?.assumedRoleId !== 2">
   <ng-container *ngIf="!rowValue?.assigneeId && !rowValue?.status?.toLowerCase()?.includes('cancelled') && !rowValue?.status?.toLowerCase()?.includes('completed')">
       <button *ngIf="((!rowValue?.isTaskHighestPriority) || (rowValue.priority === 0))" mat-flat-button class="ig2 button button--secondary button--xsmall" (click)="onReorderClick()"> Top </button>
   </ng-container>
 </ng-container>
  `,
  styles: [
  ]
})
export class ReorderCellRendererComponent implements ICellRendererAngularComp {

  cellValue!: string;
  rowValue!: ITaskSummary;
  eRowValue!: IEmailSummary;
  processId!: string;
  isEmailDashboard!: boolean;
  params!: ICellRendererParams<any, any> & ReorderParams;
  userInfo!: UserInfo;


  constructor(private dashboardService: DashboardService, private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.userInfo = getUserInfo();
  }

  agInit(params: ICellRendererParams<any, any> & ReorderParams): void {
    this.cellValue = params.value;
    this.rowValue = params.data;
    this.eRowValue = params.data;
    this.processId = params.getProcessId();
    this.isEmailDashboard = params.isEmailDashboard();
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any> & ReorderParams): boolean {
    return false;
  }

  onReorderClick() {
    this.processId = this.params.getProcessId();
    this.isEmailDashboard = this.params.isEmailDashboard();
    let reOrderSub;
    if (!this.isEmailDashboard) {
      this.utilityService.setSpinner(true);
      this.dashboardService.reorder(this.processId, this.rowValue.id).pipe(
        finalize(() => {
          this.utilityService.setSpinner(false);
        })
      ).subscribe({
        next: () => {
          this.utilityService.showToast.emit({ message: `File "${this.rowValue.fileNumber}" is moved to the top`, isError: false });
          reloadEvent.emit();
        },
        error: (error) => {
          if (error.error.Type === "HttpRequestException") {
            this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
          }
          else {
            this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
          }
        }
      })
    }
    else {
      reOrderSub = this.dashboardService.reorderEmail(this.processId, this.eRowValue.id);
      this.utilityService.setSpinner(true);
      reOrderSub.pipe(
        finalize(() => {
          this.utilityService.setSpinner(false);
        })
      ).subscribe({
        next: () => {
          this.utilityService.showToast.emit({ message: `Email "${this.eRowValue.subject}" is moved to the top`, isError: false });
          reloadEvent.emit();
        },
        error: (error) => {
          if (error.error.Type === "HttpRequestException") {
            this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
          }
          else {
            this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
          }
        }
      })
    }
  }

}
