import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { delay, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ReAssignPopupComponent } from '../components/re-assign-popup/re-assign-popup.component';
import { AssigneeData } from '../models/assignee.interface';
import { SLADateTime } from '../models/slaDateTime';
import { IEmailSummary, ITaskSummary } from '../models/task-summary.interface';
import { ReAssignPopupEmailComponent } from '../components/re-assign-popup-email/re-assign-popup-email.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  readonly GTbaseUrl = `${environment.genericTaskBase.apiUrl}`;
  readonly EmailbaseUrl = `${environment.EmailBase.apiUrl}`;
  readonly DomainDataSearchbaseUrl = `${environment.domainDataSearchBase.apiUrl}`;
  readonly StaffingBaseUrl = `${environment.staffingBase.apiUrl}`;

  constructor(private httpClient: HttpClient, private matDialog: MatDialog) { }

  getTasks(assumedRoleId: number, processId: string, statusId: number, pageNo: number, pageSize: number, sort: { Field: string, Direction: 1 | 2 } | null): Observable<{ totalItemCount: number, items: ITaskSummary[] }> {
    const role = assumedRoleId === 2 ? "processor" : "manager";
    return this.httpClient.post<{
      totalItemCount: number,
      totalPageCount: number,
      pageNumber: number,
      count: number,
      pageSize: number,
      items: ITaskSummary[],
    }>(`${this.GTbaseUrl}/gentask/v1/task/${role}/${processId}/${statusId}/list`,
      {
        PageRequest: {
          PageNumber: pageNo,
          PageSize: pageSize
        },
        SortRequest: sort,
        Filters: []
      }
    ).pipe(
      map(pagedResponse => {
        return { totalItemCount: pagedResponse.totalItemCount, items: pagedResponse.items };
      }),
      map(resp => {
        const items = resp.items.map(d => {
          d.slainsecs = ((new Date(d.dueDate).getTime() - new Date(d.orderDate).getTime()) / 1000).toString();
          d.timeToSLAinsecs = ((new Date(d.dueDate).getTime() - new Date().getTime()) / 1000).toString();
          const hhmmSLA = this.slaDateTime(d.timeToSLAinsecs);
          d.timeToSLA = hhmmSLA.getDays === "0" ? ` ${hhmmSLA.getHours}h ${hhmmSLA.getMinutes}m` :
            `${hhmmSLA.getDays}d ${hhmmSLA.getHours}h ${hhmmSLA.getMinutes}m`;
          d.timeToSLA = +d.timeToSLAinsecs > 0 ? d.timeToSLA : ("-" + d.timeToSLA);
          d.assignee = !d?.assigneeId ? "Unassigned" : d.assignee;
          d.assignee = d.assigneeSite ? d.assignee + '/' + d.assigneeSite : d.assignee;
          return d;
        });
        return { totalItemCount: resp.totalItemCount, items };
      })
    );
  }
  getTaskEmail(processId: string, statusId: number, pageNo: number, pageSize: number,
    sort: { Field: string, Direction: 1 | 2 } | null): Observable<{ totalItemCount: number, items: IEmailSummary[] }> {
    return this.httpClient.post<{
      totalItemCount: number,
      totalPageCount: number,
      pageNumber: number,
      count: number,
      pageSize: number,
      items: IEmailSummary[],
    }>(`${this.EmailbaseUrl}/email/status/${statusId}/process/${processId}`,
      {
        PageRequest: {
          PageNumber: pageNo,
          PageSize: pageSize
        },
        SortRequest: sort,
        Filters: []
      }
    ).pipe(
      map(pagedResponse => {
        return { totalItemCount: pagedResponse.totalItemCount, items: pagedResponse.items };
      }),
      map(resp => {
        const items = resp.items.map(d => {
          d.slainsecs = ((new Date(d.dueDate).getTime() - new Date(d.orderDate).getTime()) / 1000).toString();
          d.timeToSLAinsecs = ((new Date(d.dueDate).getTime() - new Date().getTime()) / 1000).toString();
          const hhmmSLA = this.slaDateTime(d.timeToSLAinsecs);
          d.timeToSLA = hhmmSLA.getDays === "0" ? ` ${hhmmSLA.getHours}h ${hhmmSLA.getMinutes}m` :
            `${hhmmSLA.getDays}d ${hhmmSLA.getHours}h ${hhmmSLA.getMinutes}m`;
          d.timeToSLA = +d.timeToSLAinsecs > 0 ? d.timeToSLA : ("-" + d.timeToSLA);
          d.assignee = !d?.assigneeId ? "Unassigned" : d.assignee;
          d.assignee = d.assigneeSite ? d.assignee + '/' + d.assigneeSite : d.assignee;
          return d;
        });
        return { totalItemCount: resp.totalItemCount, items };
      })
    );
  }

  slaDateTime(seconds: string): SLADateTime {
    // const secs = Number(seconds);
    // let hours!: number;
    // let minutes!: number;
    // let days!: number;
    // if (secs > 0) {
    //   days = Math.floor(secs / 86400);
    //   hours = Math.floor((secs - days * 86400) / 3600);
    //   minutes = Math.floor((secs - days * 86400 - hours * 3600) / 60);
    // }
    // else {
    //   days = Math.ceil(secs / 86400);
    //   hours = Math.ceil((secs - days * 86400) / 3600);
    //   minutes = Math.ceil((secs - days * 86400 - hours * 3600) / 60);
    // }
    // hours = hours < 0 ? hours * -1 : hours;
    // minutes = minutes < 0 ? minutes * -1 : minutes;
    // const hhmm = `${days}:${hours}:${minutes.toString().length === 1 ? '0' + minutes : minutes}`;
    // return {
    //   getHoursMinutes: hhmm,
    //   getHours: hhmm.split(':')[1],
    //   getMinutes: hhmm.split(':')[2],
    //   getDays: hhmm.split(':')[0]
    // }
    // get total seconds between the times
    let delta = Math.abs(+seconds);

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    return {
      getHours: hours.toString(),
      getMinutes: minutes < 9 ? "0" + minutes : minutes.toString(),
      getDays: days.toString()
    }
  }

  searchTasks(processId: string, searchText: string, pageNo: number, pageSize: number, sort: { Field: string, Direction: 1 | 2 } | null): Observable<{ totalItemCount: number, items: ITaskSummary[] }> {
    if (searchText.trim().length === 0) {
      return of();
    }
    return this.httpClient.post<{
      totalItemCount: number,
      totalPageCount: number,
      pageNumber: number,
      count: number,
      pageSize: number,
      items: ITaskSummary[],
    }>(`${this.GTbaseUrl}/gentask/v1/task/${processId}/list/${searchText}`,
      {
        PageRequest: {
          PageNumber: pageNo,
          PageSize: pageSize
        },
        SortRequest: sort,
        Filters: []
      }
    ).pipe(
      map(pagedResponse => {
        return { totalItemCount: pagedResponse.totalItemCount, items: pagedResponse.items };
      }),
      map(resp => {
        const items = resp.items.map(d => {
          d.slainsecs = ((new Date(d.dueDate).getTime() - new Date(d.orderDate).getTime()) / 1000).toString();
          d.timeToSLAinsecs = ((new Date(d.dueDate).getTime() - new Date().getTime()) / 1000).toString();
          const hhmmSLA = this.slaDateTime(d.timeToSLAinsecs);
          d.timeToSLA = hhmmSLA.getDays === "0" ? ` ${hhmmSLA.getHours}h ${hhmmSLA.getMinutes}m` :
            `${hhmmSLA.getDays}d ${hhmmSLA.getHours}h ${hhmmSLA.getMinutes}m`;
          d.timeToSLA = +d.timeToSLAinsecs > 0 ? d.timeToSLA : ("-" + d.timeToSLA);
          d.assignee = !d?.assigneeId ? "Unassigned" : d.assignee;
          d.assignee = d.assigneeSite ? d.assignee + '/' + d.assigneeSite : d.assignee;
          return d;
        });
        return { totalItemCount: resp.totalItemCount, items };
      })
    );
  }

  getAssigneeData(processId: string): Observable<AssigneeData[]> {
    return this.httpClient.get<{
      id: string;
      name: string;
      role: string;
      siteName: string;
    }[]>(`${this.StaffingBaseUrl}/staff/v1/process/${processId}/dropdown`).pipe(
      map(response => {
        return response.map(item => {
          return {
            ...item,
            site: item.siteName
          } as AssigneeData;
        })
      })
    );
  }

  getAssigneeOnSearch(prdsysId: string, searchText: string): Observable<AssigneeData[]> {
    const body = {
      PageRequest: {
        PageNumber: 1,
        PageSize: 50
      },
      Filters: [
        {
          Field: "FirstName LastName",
          Value: searchText
        }
      ]
    };
    return this.httpClient.post<{
      totalItemCount: number,
      totalPageCount: number,
      pageNumber: number,
      count: number,
      pageSize: number,
      items: AssigneeData[],
    }>(`${this.DomainDataSearchbaseUrl}/staff/v1/user/predictivesearch/prdsys/${prdsysId}`, body).pipe(
      map(data => {
        return data.items;
      })
    );
  }

  public openActionDialog(processId: string, rowData: ITaskSummary) {
    return this.matDialog.open(ReAssignPopupComponent, {
      width: '310px',
      height: '366px',
      panelClass: ['ig2'],
      data: {
        heading: "Reassign",
        rowData,
        processId
      },
      disableClose: true,
    });
  }

  public openActionDialogEmailReassign(processId: string, rowData: IEmailSummary) {
    return this.matDialog.open(ReAssignPopupEmailComponent, {
      width: '310px',
      height: '366px',
      panelClass: ['ig2'],
      data: {
        heading: "Reassign",
        rowData,
        processId
      },
      disableClose: true,
    });
  }

  reorder(processId: string, taskId: string) {
    return this.httpClient.post(`${this.GTbaseUrl}/gentask/v1/process/${processId}/task/${taskId}/reorder`, null);
  }

  saveAssignee(taskId: string, assigneeId: string) {
    return this.httpClient.post(`${this.GTbaseUrl}/gentask/v1/task/${taskId}/staff/${assigneeId}`, null);
  }

  UnAssign(taskId: string) {
    return this.httpClient.post(`${this.GTbaseUrl}/gentask/v1/task/${taskId}/unassign`, null);
  }
  getStatusTaskAssignedToUser(processId: string) {
    return this.httpClient.get(`${this.GTbaseUrl}/gentask/v1/process/${processId}/task/assigned`);
  }
  reorderEmail(processId: string, emailId: string) {
    return this.httpClient.post(`${this.EmailbaseUrl}/process/${processId}/email/${emailId}/reorder`, null);
  }
  ReAssignEmail(emailId: string, assigneeId: string) {
    return this.httpClient.post(`${this.EmailbaseUrl}/email/${emailId}/staff/${assigneeId}`, null);
  }
  UnAssignEmail(emailId: string) {
    return this.httpClient.post(`${this.EmailbaseUrl}/email/${emailId}/unassign`, null);
  }
  getprocessdetails(processId: any) {
    return this.httpClient.get(`${environment.igpsBase.apiUrl}/domaindata/prdprocess/v1/id/${processId}`);
  }

}
