<div class="main-container">

  <hr class="dashboard-divider">

  <div>
    <span class="heading">
      Inbox Summary
    </span>
    <br>
    <span class="updated-text"> Updated {{updatedTime | convertTime: 'local' : timezone.value }} </span>
    <button class="tertiary" (click)="refreshGrid()">
      <lib-icon name="refresh" size="small"></lib-icon>
      Reload
    </button>
  </div>

  <div class="search-container">
    <div class="label-input-labelwrapper">
      <mat-form-field class="search-emails-input ig2 mat-form-field-no-animation" appearance="fill">
        <mat-label id="searchEmails" class="mat-label-wrap">Search Emails</mat-label>
        <input class="mat-input-element-show-placeholder" id="searchEmails" matInput
          placeholder="Search by File Number, Assigned To, Subject, and From" (keyup)="searchEvent.emit($event)"
          [(ngModel)]="searchText" />
        <svg *ngIf="searchText.trim().length > 0" (click)="clearSearch()" class="clear-icon" height="16"
          viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 3a9 9 0 1 1 0 18.001A9 9 0 0 1 12 3ZM7.05 7.05a1 1 0 0 0-.083 1.32l.083.094L10.586 12 7.05 15.536a1 1 0 0 0-.083 1.32l.083.094a1 1 0 0 0 1.414 0L12 13.414l3.536 3.536a1 1 0 0 0 1.497-1.32l-.083-.094L13.414 12l3.536-3.536a1 1 0 0 0 .083-1.32l-.083-.094a1 1 0 0 0-1.414 0L12 10.586 8.464 7.05a1 1 0 0 0-1.414 0Z"
            fill="#043B7C"></path>
        </svg>
        <lib-icon class="search-task-icon" name="magnifying_glass" size="standard" (click)="onClickSearch()"></lib-icon>
      </mat-form-field>

      <mat-form-field id="received-date-from" class="ig2 mat-form-field-no-animation mat-form-field-datepicker"
        appearance="fill">
        <mat-label>Received Date From</mat-label>
        <div class="input-control">
          <input class="mat-input-element-show-placeholder" matInput [max]="maxDate" [matDatepicker]="rdfDatePicker"
            [(ngModel)]="receivedDateFrom" (dateChange)="onChangeDate()" placeholder="xx/xx/xxxx" />
          <svg *ngIf="isDateFromValid" (click)="clearFromDate()" class="clear-icon" height="32"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12 3a9 9 0 1 1 0 18.001A9 9 0 0 1 12 3ZM7.05 7.05a1 1 0 0 0-.083 1.32l.083.094L10.586 12 7.05 15.536a1 1 0 0 0-.083 1.32l.083.094a1 1 0 0 0 1.414 0L12 13.414l3.536 3.536a1 1 0 0 0 1.497-1.32l-.083-.094L13.414 12l3.536-3.536a1 1 0 0 0 .083-1.32l-.083-.094a1 1 0 0 0-1.414 0L12 10.586 8.464 7.05a1 1 0 0 0-1.414 0Z"
              fill="#043B7C"></path>
          </svg>
          <mat-datepicker-toggle class="ig2" matSuffix [for]="rdfDatePicker">
            <mat-icon matDatepickerToggleIcon (click)="rdfDatePicker.open()">
              <img src="/assets/icons/icon-calendar-dark.svg" alt="calendar icon" />
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker panelClass="ig2" #rdfDatePicker></mat-datepicker>
        </div>
      </mat-form-field>

      <mat-form-field id="received-date-to"
        class="search-emails-input ig2 ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
        <mat-label>Received Date To</mat-label>
        <div class="input-control">
          <input class="mat-input-element-show-placeholder" matInput [max]="maxDate" [matDatepicker]="rdtDatePicker"
            [(ngModel)]="receivedDateTo" (dateChange)="onChangeDate()" placeholder="xx/xx/xxxx" />
            <svg *ngIf="isDateToValid" (click)="clearToDate()" class="clear-icon" height="32"
              viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12 3a9 9 0 1 1 0 18.001A9 9 0 0 1 12 3ZM7.05 7.05a1 1 0 0 0-.083 1.32l.083.094L10.586 12 7.05 15.536a1 1 0 0 0-.083 1.32l.083.094a1 1 0 0 0 1.414 0L12 13.414l3.536 3.536a1 1 0 0 0 1.497-1.32l-.083-.094L13.414 12l3.536-3.536a1 1 0 0 0 .083-1.32l-.083-.094a1 1 0 0 0-1.414 0L12 10.586 8.464 7.05a1 1 0 0 0-1.414 0Z"
                fill="#043B7C"></path>
            </svg>
          <mat-datepicker-toggle class="ig2" matSuffix [for]="rdtDatePicker">
            <mat-icon matDatepickerToggleIcon (click)="rdtDatePicker.open()">
              <img src="/assets/icons/icon-calendar-dark.svg" alt="calendar icon" />
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker panelClass="ig2" #rdtDatePicker></mat-datepicker>
        </div>
      </mat-form-field>

      <mat-slide-toggle class="ig2" [(ngModel)]="isSearchAllStatus" (change)="onToggle()">
        <span>Search All {{toggleText}}</span>
      </mat-slide-toggle>

    </div>

  </div>

  <div class="grid-container">
    <!-- <igps-lib-spinner class="spinner" [showSpinner]="(showGridSpinner)"></igps-lib-spinner>     -->
    <app-email-manager-grid (updateTime)="updateTime()" [refreshgrid]="refreshGridData"></app-email-manager-grid>

  </div>

</div>