  <div mat-dialog-title class="mat-dialog-title">
      <div> <span class="heading-text">{{ data.heading }}</span> </div>
      <button mat-button mat-dialog-close (click)='close(" ")'> X </button>
  </div>

  <mat-dialog-content class="mat-typography mat-dialog-content ">
      <span class="label-text">Please select the assignee to reassign the task</span>
      <div class="padding-top-reassign">
        <model-filter-select class="ig2"
          label="Reassign"
          [options]="assignees"
          placeholder="Select Assignee"
          (searchChange)="search.emit($event)"
          searchPlaceholder="Search Assignee"
          [sort]="false"
          noMatchFoundText="No User Found."
          [(searchText)]="searchText"
          [(isLoading)]="isUsersLoading"
          [(selectedValue)]="selectedAssigneeId"
          [forceShowSearch]="true"
        >
          <ng-template let-item #option>
            <div *ngIf="item.id != 'null'">
              <div> {{item.name}} </div>
              <div class="sub-label"> <span>{{item.role}}</span>, <span>{{item.site}}</span> </div>
            </div>
            <span *ngIf="item.id == 'null'">{{item.name}}</span>
          </ng-template>
        </model-filter-select>
        <!-- <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
          <mat-label id="lblReassign" class="mat-label-wrap">Reassign</mat-label>
          <mat-select id="selectReassign" class="ig2" placeholder="Select an assignee" [(ngModel)]="selectedAssigneeId">
              <div class="searchInput">
                <div class="label-input-labelwrapper">
                  <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
                    <input class="mat-input-element-show-placeholder" id="searchAssignee" matInput
                      placeholder="Search assignee" (keyup)="search.emit($event)" [(ngModel)]="searchText" />
                    <svg viewBox="0 0 24 24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.5 2a7.5 7.5 0 0 1 5.645 12.438l6.022 6.022a.5.5 0 0 1-.707.707l-6.022-6.022A7.5 7.5 0 1 1 9.5 2Zm0 1a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Z"
                        fill="#043B7C"></path>
                    </svg>
                  </mat-form-field>
                </div>
              </div>
              <div class="options" [ngClass]="{'overflow-y-hidden': isUsersLoading}">
                <ng-container *ngIf="!isUsersLoading">
                  <mat-option *ngFor="let assignee of assignees" [value]="assignee.id">
                    <div *ngIf="assignee.id != 'null'">
                      <div> {{assignee.name}} </div>
                      <div class="sub-label"> <span>{{assignee.role}}</span>, <span>{{assignee.site}}</span> </div>
                    </div>
                    <span *ngIf="assignee.id == 'null'">{{assignee.name}}</span>
                  </mat-option>
                  <div class="option-container" *ngIf="assignees.length == 0">
                    <span> No Users Found </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="isUsersLoading">
                  <div class="option-container">
                    <div class="loader"></div>
                  </div>
                  <mat-option style="display: none"></mat-option>
                </ng-container>
              </div>
          </mat-select>
        </mat-form-field> -->
      </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mat-dialog-actions" align="end" >
      <button mat-flat-button class="ig2 button button--primary button--small mat-button mat-button-base" [disabled]="((!selectedAssigneeId) || (data.rowData.assigneeId === selectedAssigneeId) || (data.rowData.assigneeId === null && selectedAssigneeId === 'null'))" (click)="onSave()"
          cdkFocusInitial>Save</button>
  </mat-dialog-actions>
