<div mat-dialog-title class="mat-dialog-title">
    <div> <span class="heading-text">{{ data.heading }}</span> </div>
    <button mat-button mat-dialog-close (click)='close(" ")'> X </button>
</div>

<mat-dialog-content class="mat-typography mat-dialog-content ">
    <span class="label-text">Please select the assignee to reassign the task</span>
    <div class="padding-top-reassign">
      <model-filter-select class="ig2"
        label="Reassign"
        [options]="assignees"
        placeholder="Select Assignee"
        (searchChange)="search.emit($event)"
        searchPlaceholder="Search Assignee"
        [sort]="false"
        noMatchFoundText="No User Found."
        [(searchText)]="searchText"
        [(isLoading)]="isUsersLoading"
        [(selectedValue)]="selectedAssigneeId"
        [forceShowSearch]="true"
      >
        <ng-template let-item #option>
          <div *ngIf="item.id != 'null'">
            <div> {{item.name}} </div>
            <div class="sub-label"> <span>{{item.role}}</span>, <span>{{item.site}}</span> </div>
          </div>
          <span *ngIf="item.id == 'null'">{{item.name}}</span>
        </ng-template>
      </model-filter-select>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-actions" align="end" >
    <button mat-flat-button class="ig2 button button--primary button--small mat-button mat-button-base" [disabled]="((!selectedAssigneeId) || (data.rowData.assigneeId === selectedAssigneeId) || (data.rowData.assigneeId === null && selectedAssigneeId === 'null'))" (click)="onSave()"
        cdkFocusInitial>Save</button>
</mat-dialog-actions>
