import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { getTimezone, getUserInfo, ipsWsMessages$, timezone$ } from '@igps/client-root-config';
import { ConvertTimePipe } from '@igps/pipe';
import { EmailTaskId, EmailMessageId, selectedTaskId } from '@igps/top-utility';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColumnApi, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, IDatasource, IDetailCellRendererParams, IGetRowsParams, ITooltipParams, RowHeightParams } from 'ag-grid-community';
import { Subscription, finalize, map } from 'rxjs';
import { EmailDashboardService } from '../../services/emaildashboard.service';
import * as myJson from '../../../_mock_/managerResponesMock.json';
import { ISearchEmailPayload, UserInfo } from '../../../app/models';
import { Events } from '../../../app/models/Events';
import { ProcessDataModel } from '../../../app/models/processDataModel';
import { IEmailSummary } from '../../../app/models/task-summary.interface';
import { IMessage } from '../../../app/models/wsmessage';
import { DashboardService } from '../../../app/services/dashboard.service';
import { UtilityService } from '../../../app/services/utility.service';
import { cardSelectEvent, resetTimerEvent, searchEmailInputEvent, selectedProcessEvent } from '../../../main.single-spa';
import { AsigneeParams, AssigneeCellRendererComponent } from '../assignee-cell-renderer/assignee-cell-renderer.component';
import { EmailCellRendererComponent } from '../email-cell-renderer/email-cell-renderer.component';
import { NotesCellRendererComponent } from '../notes-cell-renderer/notes-cell-renderer.component';
import { ReorderCellRendererComponent, ReorderParams } from '../reorder-cell-renderer/reorder-cell-renderer.component';
import { SubjectCellRendererComponent } from '../subject-cell-renderer/subject-cell-renderer.component';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  selector: 'app-email-manager-grid',
  templateUrl: './email-manager-grid.component.html',
  styleUrls: ['./email-manager-grid.component.scss']
})
export class EmailManagerGridComponent implements OnInit, OnDestroy {
  @Output() updateTime: EventEmitter<any> = new EventEmitter<any>();
  @Input() refreshgrid: any;
  @ViewChild("grid", { static: false }) grid!: AgGridAngular;
  @ViewChild("ag-body-viewport") gridBody!: HTMLElement;
  showSpinner: boolean = false;
  isLoading: boolean = false;
  userInfo!: UserInfo;
  EMailjson = myJson;
  onShoreToggle!: boolean;

  dashboardSelectedCard!: { card: { id: number; cardName: string; count: number; } | null, processId: string };
  emailSearchPayload!: ISearchEmailPayload;
  gridApi!: GridApi;
  gridColumnApi!: ColumnApi;
  pageSize: number = 20;
  cellRendererComponents = {
    subject: SubjectCellRendererComponent,
    notes: NotesCellRendererComponent,
    emailMessage: EmailCellRendererComponent,
    assignee: AssigneeCellRendererComponent,
    reorder: ReorderCellRendererComponent,
    tooltip: TooltipComponent
  }
  selectedRow: IEmailSummary | null = null;
  selectedProcessData!: ProcessDataModel;

  cardSelectEventSubscription!: Subscription;
  searchEventSubscription!: Subscription;
  WebSockectSubscription!: Subscription;
  selectedProcessEventSubscription!: Subscription;
  timezoneSubscription!: Subscription;

  defaultColumnDefs: ColDef = {
    suppressNavigable: true,
    editable: false,
    cellClass: 'no-border'
  }
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };

  ColumnDefs: ColDef[] = [
    {
      headerName: "Subject",
      field: "subject",
      cellRenderer: "subject",
      wrapText: true,
      sortable: true,
      // tooltipField: "subject",
      minWidth: 300,
      // tooltipComponent: "tooltip"
    },
    {
      headerName: "Notes",
      field: "totalNotesComments",
      cellRenderer: "notes",
      minWidth: 30,
      maxWidth: 70
    },
    {
      headerName: "Message",
      field: "emailMessage",
      cellRenderer: "emailMessage",
      minWidth: 30,
      maxWidth: 70,
      tooltipField: "emailMessage"
    },
    {
      headerName: "From",
      field: "from",
      sortable: true,
      minWidth: 156,
      tooltipValueGetter: (params: ITooltipParams) => {
        if (params.value) {
          return `${params.value.split(':')[0]?.trim()} <${params.value.split(':')[1]?.trim()}>`;
        }
        return '';
      },
      cellRenderer: (params: IDetailCellRendererParams) => {
        if (params.value) {
          return `
            <span> ${params.value.split(':')[0]?.trim()} </span>
          `;
        }
        return '';
      }
    },
    {
      headerName: "Received",
      field: "received",
      sortable: true,
      minWidth: 120,
      tooltipValueGetter: (params: ITooltipParams) => {
        if (params.value) {
          return this.getTimezoneDate(params.value);
        }
        return '';
      },
      cellRenderer: (params: IDetailCellRendererParams) => {
        if (params.value) {
          const pstDateTime = this.getTimezoneDate(params.value);
          return `
            <span> ${pstDateTime} </span>
          `;
        }
        return '';
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (new Date(valueA).getTime() === new Date(valueB).getTime()) return 0;
        return (new Date(valueA).getTime() > new Date(valueB).getTime()) ? 1 : -1;
      }
    },
    {
      headerName: "Time to SLA",
      field: "timeToSLA",
      minWidth: 20,
      maxWidth: 170,
      cellClass: "grid-cell-centered",
      headerClass: "grid-cell-centered",
      cellRenderer: (params: IDetailCellRendererParams) => {
        if (!params.data) {
          return '';
        }
        let className: string = "";
        // (SLA/4) < time to SLA <=  (SLA/2)
        if (((+(params?.data as IEmailSummary)?.slainsecs / 4) < (+(params?.data as IEmailSummary)?.timeToSLAinsecs))
          && ((+(params?.data as IEmailSummary)?.timeToSLAinsecs) <= (+(params?.data as IEmailSummary)?.slainsecs / 2))) {
          className = "yellow";
        }
        // Time to SLA <= (SLA/4)
        else if (((+(params?.data as IEmailSummary)?.timeToSLAinsecs) <= (+(params?.data as IEmailSummary)?.slainsecs / 4))) {
          className = "red";
        }
        return `
          <div class="sla ${className}"> ${params.value} </div>
        `;
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (+(nodeA?.data as IEmailSummary)?.timeToSLAinsecs === +(nodeB?.data as IEmailSummary)?.timeToSLAinsecs) return 0;
        return (+(nodeA?.data as IEmailSummary)?.timeToSLAinsecs > +(nodeB?.data as IEmailSummary)?.timeToSLAinsecs) ? 1 : -1;
      },
      sortable: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      tooltipField: "status",
      minWidth: 20,
      maxWidth: 170,
    },
    {
      headerName: "Assignee",
      field: "assignee",
      tooltipField: "assignee",
      cellRenderer: "assignee",
      cellRendererParams: {
        getProcessId: () => this.dashboardSelectedCard.processId,
        isEmailDashboard: () => true
      } as AsigneeParams,
      sortable: true,
      minWidth: 80
    },
    {
      headerName: "Date Assigned",
      field: "dateAssigned",
      sortable: true,
      tooltipField: "dateAssigned",
      minWidth: 175
    },
    {
      headerName: "File Number",
      field: "fileNumber",
      sortable: true,
      tooltipField: "fileNumber",
      minWidth: 40,
      maxWidth: 120
    },
    {
      headerName: "Completion Date",
      headerTooltip: "Completion Date",
      field: "endDate",
      sortable: true,
      minWidth: 20,
      tooltipValueGetter: (params: ITooltipParams) => {
        if (params.value) {
          return this.getTimezoneDate(params.value);
        }
        return '';
      },
      cellRenderer: (params: IDetailCellRendererParams) => {
        if (params.value) {
          const pstDateTime = this.getTimezoneDate(params.value);
          return `
            <span> ${pstDateTime} </span>
          `;
        }
        return '';
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (new Date(valueA).getTime() === new Date(valueB).getTime()) return 0;
        return (new Date(valueA).getTime() > new Date(valueB).getTime()) ? 1 : -1;
      }
    },
    {
      headerName: "",
      field: "reorder",
      cellRenderer: "reorder",
      maxWidth: 110,
      minWidth: 90,
      cellRendererParams: {
        getProcessId: () => this.dashboardSelectedCard.processId,
        isEmailDashboard: () => true
      } as ReorderParams,
    }
  ];

  constructor(private convertTime: ConvertTimePipe, private dashboardService: DashboardService, private router: Router, private zone: NgZone,
    private datePipe: DatePipe, private utilityService: UtilityService, private cdref: ChangeDetectorRef, private emailService: EmailDashboardService) { }
  ngOnDestroy(): void {
    this.cardSelectEventSubscription?.unsubscribe();
    this.searchEventSubscription?.unsubscribe();
    this.WebSockectSubscription?.unsubscribe();
    this.selectedProcessEventSubscription?.unsubscribe();
    this.timezoneSubscription?.unsubscribe();
    window.removeEventListener('resize', this.resizeListenerFunc);
    window.removeEventListener('click', this.resizeListenerFunc);
  }

  ngOnChanges(): void {
    this.getSelectedCardData();
  }

  private resizeListenerFunc = () => { this.gridApi.sizeColumnsToFit(); };

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };

  private getTimezoneDate(dateTime: string) {
    const pstDate: string = this.convertTime.transform(dateTime, 'utc', this.timezone.value);
    return pstDate ? pstDate : "N/A";
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener('resize', this.resizeListenerFunc);
    window.addEventListener('click', this.resizeListenerFunc);
  }

  onScroll() {
    resetTimerEvent.emit();
  }

  ngOnInit(): void {
    this.clearSessionData();
    this.userInfo = getUserInfo();
    this.cardSelectEventSubscription = cardSelectEvent.subscribe(d => {
      this.dashboardSelectedCard = d;
      if (!d.isAutoRefresh) {
        this.clearSessionData();
        if (d.card != null) {
          this.getSelectedCardData();
        }
        else if (this.emailSearchPayload.search.length !== 0 || this.emailSearchPayload.fromDate!.length !== 0 || this.emailSearchPayload.toDate!.length !== 0) {
          this.getSearchEmailData(this.emailSearchPayload);
        }
      }
    });
    this.selectedProcessEventSubscription = selectedProcessEvent.subscribe(d => {
      this.selectedProcessData = d;
      this.onShoreToggle = d.hasOnshoreQueue
    });
    this.WebSockectSubscription = ipsWsMessages$.subscribe((message: any) => {
      const data: IMessage = message as IMessage;
      if (Events.TriggerEvents.includes(data?.EventName?.toLowerCase())) {
        if (data?.Payload?.EmailMessageId === this.selectedRow?.id) {
          this.onSelectionChanged();
        }
        // this.getSelectedCardData();
      }
    });
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
      this.gridApi?.refreshCells({ force: true });
    });

    this.searchEventSubscription = searchEmailInputEvent
      .subscribe((e) => {
        this.clearSessionData();
        this.emailSearchPayload = e;
        this.getSearchEmailData(e);
      });
  }

  clearSessionData(){
    sessionStorage.removeItem('SearchResultTaskId');
    sessionStorage.removeItem('selectedEmailNoteTaskId');
    sessionStorage.removeItem('selectedTaskId');
    sessionStorage.removeItem('emailStatusId');
    sessionStorage.removeItem('emailAssignedTo');
    sessionStorage.removeItem('selectedNoteTaskId');
    selectedTaskId.emit(null);
    EmailTaskId.emit(null);
    EmailMessageId.emit('');
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedRow = null;
    if (selectedRows?.length === 1) {
      this.selectedRow = selectedRows[0];
    }
    if (this.selectedRow) {
      EmailTaskId.emit(this.selectedRow.taskId);
      EmailMessageId.emit(this.selectedRow.id);
      if(this.selectedRow.taskId){
        selectedTaskId.emit(this.selectedRow.taskId);
      }else{
        selectedTaskId.emit(this.selectedRow.id);
      }
    }
    else {
      selectedTaskId.emit(null);
      EmailTaskId.emit(null);
      EmailMessageId.emit('');
    }
    this.gridApi.sizeColumnsToFit();
  }

  onDoubleClick() {
    this.clearSessionData();
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedRow = null;
    if (selectedRows?.length === 1) {
      this.selectedRow = selectedRows[0];
      if (this.selectedRow?.taskId) {
        EmailMessageId.emit(this.selectedRow.id);
        EmailTaskId.emit(this.selectedRow.taskId);
        selectedTaskId.emit(this.selectedRow.id);
        this.router.navigate(['/process/task', this.selectedRow.taskId, { emailId: this.selectedRow?.id }], { state: { emailId: this.selectedRow?.id } });
      }
      else {
        if (this.selectedRow?.id) {
          // this.router.navigate(['/file-search', this.selectedRow?.id]); // email details to be passed based on MFE
          this.router.navigateByUrl('/email-search', {
            state: {
              emailId: this.selectedRow?.id, emailStatusId: this.selectedRow?.statusId,
              assigneeId: this.selectedRow.assigneeId, onShoreToggle: this.onShoreToggle
            }
          });
        }
      }
    }
  }

  getSelectedCardData() {
    if (this.dashboardSelectedCard?.card) {
      this.utilityService.setSpinner(true);
      let lastRow: number = -1;
      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: (params: IGetRowsParams) => {
          const startRow: number = params.startRow;
          const endRow: number = params.endRow;
          let sort: { Field: string, Direction: 1 | 2 } | null = null;
          if (params.sortModel.length > 0) {
            sort = {
              Field: params.sortModel[0].colId === 'timeToSLA' ? 'slaInSeconds' : params.sortModel[0].colId,
              Direction: params.sortModel[0].sort === 'asc' ? 1 : 2
            };
          }
          this.dashboardService.getTaskEmail(this.dashboardSelectedCard.processId,
            (this.dashboardSelectedCard.card as { id: number; cardName: string; count: number; }).id,
            (endRow / this.pageSize), this.pageSize, sort)
            .pipe(
              map(d => {
                const items = d.items.map(i => {
                  return { ...i, slainsecs: (this.selectedProcessData.slaInHours * 60 * 60) };
                });
                return { ...d, items };
              }),
              finalize(() => {
                this.utilityService.setSpinner(false);
              })
            ).subscribe({
              next: (d) => {
                selectedTaskId.emit(null);
                EmailTaskId.emit(null);
                EmailMessageId.emit('');
                this.updateTime.emit();
                this.afterDataChange();
                if (params.endRow >= d.totalItemCount) {
                  lastRow = d.totalItemCount;
                }
                params.successCallback(d.items, lastRow);
              },
              error: (error: HttpErrorResponse) => {
                if (error.error.Type === "HttpRequestException") {
                  this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
                }
                else {
                  this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
                }
              }
            })
        },
      };
      this.grid.api!.setDatasource(dataSource);
    }
  }

  onWebSockectgetSelectedCardData() {
    if (this.dashboardSelectedCard.card) {
      // this.utilityService.setSpinner(true);
      let lastRow: number = -1;
      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: (params: IGetRowsParams) => {
          const startRow: number = params.startRow;
          const endRow: number = params.endRow;
          let sort: { Field: string, Direction: 1 | 2 } | null = null;
          if (params.sortModel.length > 0) {
            sort = {
              Field: params.sortModel[0].colId === 'timeToSLA' ? 'slaInSeconds' : params.sortModel[0].colId,
              Direction: params.sortModel[0].sort === 'asc' ? 1 : 2
            };
          }
          this.dashboardService.getTaskEmail(this.dashboardSelectedCard.processId, (this.dashboardSelectedCard.card as { id: number; cardName: string; count: number; }).id, (endRow / this.pageSize), this.pageSize, sort).pipe(
            map(d => {
              const items = d.items.map(i => {
                return { ...i, slainsecs: (this.selectedProcessData.slaInHours * 60 * 60) };
              });
              return { ...d, items };
            }),
            finalize(() => {
              // this.utilityService.setSpinner(false);
            })
          ).subscribe({
            next: (d) => {
              selectedTaskId.emit(null);
              EmailTaskId.emit(null);
              EmailMessageId.emit('');
              this.updateTime.emit();
              this.afterDataChange();
              if (params.endRow >= d.totalItemCount) {
                lastRow = d.totalItemCount;
              }
              params.successCallback(d.items, lastRow);
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.Type === "HttpRequestException") {
                this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
              }
              else {
                this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
              }
            }
          })
        },
      };
      this.grid.api!.setDatasource(dataSource);
    }
  }

  getSearchEmailData(payload: ISearchEmailPayload) {
    this.utilityService.setGridSpinner(true);
    let lastRow: number = -1;
    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: (params: IGetRowsParams) => {
        const startRow: number = params.startRow;
        const endRow: number = params.endRow;
        let sort: { Field: string, Direction: 1 | 2 } | null = null;
        if (params.sortModel.length > 0) {
          sort = {
            Field: params.sortModel[0].colId === 'timeToSLA' ? 'slaInSeconds' : params.sortModel[0].colId,
            Direction: params.sortModel[0].sort === 'asc' ? 1 : 2
          };
        }
        payload.sortRequest = sort;
        payload.pageRequest.PageNumber = endRow / this.pageSize;
        this.emailService.searchEmails(this.dashboardSelectedCard.processId, payload)
          .pipe(map(d => {
            const items = d.items.map(i => {
              return { ...i, slainsecs: (this.selectedProcessData.slaInHours * 60 * 60) };
            });
            return { ...d, items };
          }),
            finalize(() => {
              this.utilityService.setGridSpinner(false);
            })
          ).subscribe({
            next: (d) => {
              selectedTaskId.emit(null);
              EmailTaskId.emit(null);
              EmailMessageId.emit('');
              this.updateTime.emit();
              this.afterDataChange();
              if (params.endRow >= d.totalItemCount) {
                lastRow = d.totalItemCount;
              }
              params.successCallback(d.items, lastRow);
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.Type === "HttpRequestException") {
                this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
              }
              else {
                this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
              }
            }
          })
      },
    };
    this.grid.api!.setDatasource(dataSource);
  }

  afterDataChange() {
    if (this.grid) {
      this.grid.columnApi.setColumnVisible('dateAssigned', false);
      switch (this.dashboardSelectedCard.card?.id) {
        case 777: // Completed
          this.grid.columnApi.setColumnVisible('endDate', true);
          this.grid.columnApi.setColumnVisible('reorder', false);
          this.grid.columnApi.setColumnVisible('timeToSLA', false);
          break;
        case 0: // My Tasks
        case 2: // In Progress
          this.grid.columnApi.setColumnVisible('endDate', false);
          this.grid.columnApi.setColumnVisible('reorder', false);
          this.grid.columnApi.setColumnVisible('timeToSLA', true);
          break;
        case 10: // In Pending
          this.grid.columnApi.setColumnVisible('endDate', false);
          this.grid.columnApi.setColumnVisible('reorder', false);
          break;
        case 11: // Lost and Found
          this.grid.columnApi.setColumnVisible('reorder', false);
          break;
        default:
          this.grid.columnApi.setColumnVisible('endDate', false);
          this.grid.columnApi.setColumnVisible('reorder', true);
          this.grid.columnApi.setColumnVisible('timeToSLA', true);
          break;
      }
    }
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.gridApi.sizeColumnsToFit();
      this.setRowHeight();
    }
  }

  setRowHeight() {
    this.gridApi.forEachNode((rowNode) => {
      if (rowNode.data) {
        const htmlRowElement = document.getElementById('email-subject' + rowNode.data.id);
        if (htmlRowElement) {
          if (htmlRowElement.getBoundingClientRect().height <= 38) {
            const height = 38;
            rowNode.data.height = height;
            rowNode.setRowHeight(height);
          }
        }
      }
    });
    this.gridApi.onRowHeightChanged();
  }

}
