<div class="main">
  <igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner>
 <!-- <div class="loader-container">
    <div class="loader" *ngIf="!isLoading"></div>
  </div> -->


  <ag-grid-angular  #grid
    class="grid_style ag-theme-alpine"
    [headerHeight]="36"
    [rowHeight]="38"
    [components]="cellRendererComponents"
    [rowSelection]="'single'"
    [rowModelType]="'infinite'"
    [enableCellTextSelection]="true"
    [defaultColDef]="defaultColumnDefs"
    [columnDefs]="ColumnDefs"
    [animateRows]="true"
    [cacheBlockSize]="pageSize"
    [cacheOverflowSize]="1"
    [maxConcurrentDatasourceRequests]="2"
    [infiniteInitialRowCount]="1"
    [maxBlocksInCache]="10"
    [getRowId]="getRowId"
    (selectionChanged)="onSelectionChanged()"
    (rowDoubleClicked)="onDoubleClick()"
    (gridReady)="onGridReady()"
    (bodyScroll)="onScroll()">
  </ag-grid-angular>
</div>

