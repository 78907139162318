import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UserInfo } from '../../../app/models';
import { getUserInfo } from '@igps/client-root-config';
import { IEmailSummary, ITaskSummary } from '../../../app/models/task-summary.interface';
import { DashboardService } from '../../../app/services/dashboard.service';
import { environment } from '../../../environments/environment';

export interface AsigneeParams{
  getProcessId: () => string;
  isEmailDashboard: () => boolean;
}

@Component({
  selector: 'app-assignee-cell-renderer',
  template: `
    <div class="assignee-container" *ngIf="cellValue !== undefined">
      <!-- <div class="green-dot" *ngIf="rowValue?.assigneeId"></div> -->
      <!-- <div class="name" *ngIf="!rowValue?.assigneeId" >Unassigned</div> -->
      <div class="name">{{cellValue}}</div>
      <ng-container *ngIf="userInfo?.assumedRoleId !== 2">
        <div class="icon" *ngIf="!rowValue?.status?.toLowerCase()?.includes('completed') && !rowValue?.status?.toLowerCase()?.includes('cancelled') && !rowValue?.status?.toLowerCase()?.includes('branch') && !rowValue?.status?.toLowerCase()?.includes('waived') && !rowValue?.status?.toLowerCase()?.includes('discarded')" (click)="onReassignClick()">
          <svg  viewBox="0 0 24 24" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m6.146 10.843 4.95 4.95a.5.5 0 1 0 .707-.707l-4.2-4.2h7.92l.29.003c5.454.066 7.053 2.023 5.106 6.2l-.106.221-.231.457.885.465.128-.249c2.619-5.187.582-8.007-5.766-8.094l-.306-.002H7.81l3.993-3.993a.5.5 0 1 0-.707-.708l-4.95 4.95a.498.498 0 0 0-.144.311v.085a.5.5 0 0 0 .144.311Zm-3.792 0 4.95 4.95a.5.5 0 1 0 .706-.707L3.414 10.49 8.01 5.894a.5.5 0 0 0-.707-.708l-4.95 4.95a.498.498 0 0 0-.144.311v.085c.01.114.058.225.145.311Z" fill="#043B7C"></path>
          </svg>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
  ]
})
export class AssigneeCellRendererComponent implements ICellRendererAngularComp, OnInit {

  constructor(private router: Router, private dashservice :DashboardService, private zone: NgZone) { }

  ngOnInit(): void {
    this.userInfo = getUserInfo();
  }

  cellValue!: string;
  rowValue!: ITaskSummary;
  erowValue!: IEmailSummary;
  processId!: string;
  params!:  ICellRendererParams<any, any> & AsigneeParams;
  userInfo!: UserInfo;
  isEmailDashboard!: boolean;

  agInit(params: ICellRendererParams<any, any> & AsigneeParams): void {
    this.cellValue = params.value;
    this.rowValue = params.data;
    this.erowValue = params.data;
    this.processId = params.getProcessId();
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any> & AsigneeParams): boolean {
    return false;
  }

  onReassignClick() {
    this.zone.run(() => {
      this.processId = this.params.getProcessId();
      this.isEmailDashboard = this.params.isEmailDashboard();
      if(!this.isEmailDashboard){
	    const dialogRef = this.dashservice.openActionDialog(this.processId, this.rowValue);
      }
      if(this.isEmailDashboard){
        const dialogRef = this.dashservice.openActionDialogEmailReassign(this.processId, this.erowValue);
        }
    });
  }

}
