import { resetTimerEvent, selectedProcessEvent } from './../../services/EventEmitters';
import { UtilityService } from './../../services/utility.service';
import { ReorderParams } from './../reorder-cell-renderer/reorder-cell-renderer.component';
import { FilenumberCellRendererComponent } from './../filenumber-cell-renderer/filenumber-cell-renderer.component';
import { DashboardService } from './../../services/dashboard.service';
import { AsigneeParams, AssigneeCellRendererComponent } from './../assignee-cell-renderer/assignee-cell-renderer.component';
import { NotesCellRendererComponent } from './../notes-cell-renderer/notes-cell-renderer.component';
import { ITaskSummary } from './../../models/task-summary.interface';
import { Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColumnApi, GetRowIdFunc, GetRowIdParams, GridApi, IDatasource, IDetailCellRendererParams, IGetRowsParams, ITooltipParams } from 'ag-grid-community';
import { ReorderCellRendererComponent } from '../reorder-cell-renderer/reorder-cell-renderer.component';
import { finalize, map, Subscription } from 'rxjs';
import { cardSelectEvent, searchInputEvent } from '../../../app/services/EventEmitters';
import { Router } from '@angular/router';
import { EmailTaskId, EmailMessageId, notesClickEvent, selectedTaskId } from '@igps/top-utility';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ConvertTimePipe } from '@igps/pipe';
import { Events } from '../../../app/models/Events';
import { getUserInfo, ipsWsMessages$, getTimezone, timezone$ } from '@igps/client-root-config';
import { IMessage } from '../../../app/models/wsmessage';
import { ProcessDataModel } from '../../../app/models/processDataModel';
import { UserInfo } from '../../../app/models';

@Component({
  selector: 'app-manager-grid',
  templateUrl: './manager-grid.component.html',
  styleUrls: ['./manager-grid.component.scss']
})
export class ManagerGridComponent implements OnInit, OnDestroy {

  @Output() updateTime: EventEmitter<any> = new EventEmitter<any>();
  @Input() refreshgrid: any;
  @ViewChild("grid", { static: false }) grid!: AgGridAngular;
  @ViewChild("ag-body-viewport") gridBody!: HTMLElement;
  showSpinner: boolean = false;
  isLoading: boolean = false;
  userInfo!: UserInfo;

  constructor(private convertTime: ConvertTimePipe, private dashboardService: DashboardService, private router: Router, private datePipe: DatePipe, private utilityService: UtilityService, private cdref: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this.cardSelectEventSubscription.unsubscribe();
    this.searchEventSubscription.unsubscribe();
    this.WebSockectSubscription.unsubscribe();
    this.selectedProcessEventSubscription.unsubscribe();
    this.timezoneSubscription.unsubscribe();
    window.removeEventListener('resize', this.resizeListenerFunc);
    window.removeEventListener('click', this.resizeListenerFunc);
  }
  ngOnChanges(): void {

    this.getSelectedCardData();

  }
  DashboardSelectedCard!: { card: { id: number; cardName: string; count: number; } | null, processId: string };
  searchText: string = "";
  gridApi!: GridApi;
  gridColumnApi!: ColumnApi;
  pageSize: number = 20;
  cellRendererComponents = {
    fileNumber: FilenumberCellRendererComponent,
    notes: NotesCellRendererComponent,
    assignee: AssigneeCellRendererComponent,
    reorder: ReorderCellRendererComponent
  }
  selectedRow: ITaskSummary | null = null;
  selectedProcessData!: ProcessDataModel;

  cardSelectEventSubscription!: Subscription;
  searchEventSubscription!: Subscription;
  WebSockectSubscription!: Subscription;
  selectedProcessEventSubscription!: Subscription;
  timezoneSubscription!: Subscription;

  defaultColumnDefs: ColDef = {
    suppressNavigable: true,
    editable: false,
    cellClass: 'no-border'
  }
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };

  private resizeListenerFunc = () => { this.gridApi.sizeColumnsToFit(); };

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };

  ColumnDefs: ColDef[] = [
    {
      headerName: "File Number",
      field: "fileNumber",
      cellRenderer: "fileNumber",
      sortable: true,
      // tooltipField: "fileNumber",
      minWidth: 30,
      maxWidth: 170
    },
    {
      headerName: "Notes",
      field: "totalNotesComments",
      cellRenderer: "notes",
      minWidth: 30,
      maxWidth: 100
    },
    {
      headerName: "Task Type",
      field: "taskType",
      sortable: true,
      minWidth: 20,
      maxWidth: 170,
      tooltipField: "taskType"
    },
    {
      headerName: "Time to SLA",
      field: "timeToSLA",
      minWidth: 20,
      maxWidth: 170,
      cellClass: "grid-cell-centered",
      headerClass: "grid-cell-centered",
      cellRenderer: (params: IDetailCellRendererParams) => {
        if (!params.data) {
          return '';
        }
        let className: string = "";
        // (SLA/4) < time to SLA <=  (SLA/2)
        if (((+(params?.data as ITaskSummary)?.slainsecs / 4) < (+(params?.data as ITaskSummary)?.timeToSLAinsecs)) && ((+(params?.data as ITaskSummary)?.timeToSLAinsecs) <= (+(params?.data as ITaskSummary)?.slainsecs / 2))) {
          className = "yellow";
        }
        // Time to SLA <= (SLA/4)
        else if (((+(params?.data as ITaskSummary)?.timeToSLAinsecs) <= (+(params?.data as ITaskSummary)?.slainsecs / 4))) {
          className = "red";
        }
        return `
          <div class="sla ${className}"> ${params.value} </div>
        `;
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (+(nodeA?.data as ITaskSummary)?.timeToSLAinsecs === +(nodeB?.data as ITaskSummary)?.timeToSLAinsecs) return 0;
        return (+(nodeA?.data as ITaskSummary)?.timeToSLAinsecs > +(nodeB?.data as ITaskSummary)?.timeToSLAinsecs) ? 1 : -1;
      },
      sortable: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      tooltipField: "status",
      minWidth: 20,
      maxWidth: 170,
    },
    {
      headerName: "Assignee",
      field: "assignee",
      cellRenderer: "assignee",
      cellRendererParams: {
        getProcessId: () => this.DashboardSelectedCard.processId,
        isEmailDashboard: () => false
      } as AsigneeParams,
      sortable: true,
      tooltipField: "assignee",
      minWidth: 20
    },
    {
      headerName: "Office",
      field: "office",
      sortable: true,
      tooltipField: "office",
      minWidth: 20
    },
    {
      headerName: "Officer",
      field: "officer",
      sortable: true,
      tooltipField: "officer",
      minWidth: 20
    },
    {
      headerName: "",
      field: "reorder",
      cellRenderer: "reorder",
      maxWidth: 110,
      minWidth: 90,
      cellRendererParams: {
        getProcessId: () => this.DashboardSelectedCard.processId,
        isEmailDashboard: () => false
      } as ReorderParams,
    },
    {
      headerName: "Completion Date",
      headerTooltip: "Completion Date",
      field: "endDate",
      hide: true,
      maxWidth: 250,
      minWidth: 20,
      tooltipValueGetter: (params: ITooltipParams) => {
        if (params.value) {
          return this.getTimezoneDate(params.value);
        }
        return '';
      },
      cellRenderer: (params: IDetailCellRendererParams) => {
        if (params.value) {
          const pstDateTime = this.getTimezoneDate(params.value);
          return `
            <span> ${pstDateTime} </span>
          `;
        }
        return '';
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (new Date(valueA).getTime() === new Date(valueB).getTime()) return 0;
        return (new Date(valueA).getTime() > new Date(valueB).getTime()) ? 1 : -1;
      },
      sortable: true,
    }
  ];

  private getTimezoneDate(dateTime: string) {
    const pstDate: string = this.convertTime.transform(dateTime, 'utc', this.timezone.value);
    return pstDate ? pstDate : "N/A";
  }

  onGridReady() {
    this.gridApi = this.grid.api;
    this.gridColumnApi = this.grid.columnApi;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener('resize', this.resizeListenerFunc);
    window.addEventListener('click', this.resizeListenerFunc);
  }

  onScroll() {
    resetTimerEvent.emit();
  }

  ngOnInit(): void {
    this.clearSessionData();
    this.userInfo = getUserInfo();
    this.cardSelectEventSubscription = cardSelectEvent.subscribe(d => {
      this.DashboardSelectedCard = d;
      if (!d.isAutoRefresh) {
        this.clearSessionData();
        if (d.card != null) {
          this.getSelectedCardData();
        }
        else if (this.searchText.length !== 0) {
          this.getSearchData();
        }
      }
    });
    this.selectedProcessEventSubscription = selectedProcessEvent.subscribe(d => {
      this.selectedProcessData = d;
    });
    this.searchEventSubscription = searchInputEvent.subscribe(d => {
      this.searchText = d.trim();
      if (d.trim().length !== 0) {
        this.getSearchData();
      }
    });
    this.WebSockectSubscription = ipsWsMessages$.subscribe((message: any) => {
      const data: IMessage = message as IMessage;
      if (Events.TriggerEvents.includes(data?.EventName?.toLowerCase())) {
        if (data?.Payload?.TaskId === this.selectedRow?.id) {
          this.onSelectionChanged();
        }
        // this.getSelectedCardData();
      }
    });
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
      this.gridApi?.refreshCells({ force: true });
    });
  }

  clearSessionData(){
    sessionStorage.removeItem('SearchResultTaskId');
    sessionStorage.removeItem('selectedEmailNoteTaskId');
    sessionStorage.removeItem('selectedTaskId');
    sessionStorage.removeItem('emailStatusId');
    sessionStorage.removeItem('emailAssignedTo');
    sessionStorage.removeItem('selectedNoteTaskId');
    selectedTaskId.emit(null);
    EmailTaskId.emit(null);
    EmailMessageId.emit('');
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedRow = null;
    if (selectedRows.length > 0) {
      this.selectedRow = selectedRows[0];
    }
    if (this.selectedRow) {
      selectedTaskId.emit(this.selectedRow.id);
      // notesClickEvent.emit(selectedRow.id);
    }
    else {
      selectedTaskId.emit(null);
    }
    this.gridApi.sizeColumnsToFit();
  }

  onDoubleClick() {
    this.clearSessionData();
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedRow = null;
    if (selectedRows.length > 0) {
      this.selectedRow = selectedRows[0];
      if (this.selectedRow) {
        selectedTaskId.emit(this.selectedRow.id);
        this.router.navigate(['/process/task', this.selectedRow.id]);
      }
    }
  }

  getSelectedCardData() {
    if (this.DashboardSelectedCard?.card) {
      this.utilityService.setSpinner(true);
      let lastRow: number = -1;
      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: (params: IGetRowsParams) => {
          const startRow: number = params.startRow;
          const endRow: number = params.endRow;
          let sort: { Field: string, Direction: 1 | 2 } | null = null;
          if (params.sortModel.length > 0) {
            sort = {
              Field: params.sortModel[0].colId === 'timeToSLA' ? 'slaInSeconds' : params.sortModel[0].colId,
              Direction: params.sortModel[0].sort === 'asc' ? 1 : 2
            };
          }
          this.dashboardService.getTasks(this.userInfo.assumedRoleId, this.DashboardSelectedCard.processId, (this.DashboardSelectedCard.card as { id: number; cardName: string; count: number; }).id, (endRow / this.pageSize), this.pageSize, sort).pipe(
            map(d => {
              const items = d.items.map(i => {
                return { ...i, slainsecs: (this.selectedProcessData.slaInHours * 60 * 60) };
              });
              return { ...d, items };
            }),
            finalize(() => {
              this.utilityService.setSpinner(false);
            })
          ).subscribe({
            next: (d) => {
              selectedTaskId.emit(null);
              this.updateTime.emit();
              this.afterDataChange();
              if (params.endRow >= d.totalItemCount) {
                lastRow = d.totalItemCount;
              }
              params.successCallback(d.items, lastRow);
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.Type === "HttpRequestException") {
                this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
              }
              else {
                this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
              }
            }
          })
        },
      };
      this.grid.api!.setDatasource(dataSource);
    }
  }

  onWebSockectgetSelectedCardData() {
    if (this.DashboardSelectedCard.card) {
      // this.utilityService.setSpinner(true);
      let lastRow: number = -1;
      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: (params: IGetRowsParams) => {
          const startRow: number = params.startRow;
          const endRow: number = params.endRow;
          let sort: { Field: string, Direction: 1 | 2 } | null = null;
          if (params.sortModel.length > 0) {
            sort = {
              Field: params.sortModel[0].colId === 'timeToSLA' ? 'slaInSeconds' : params.sortModel[0].colId,
              Direction: params.sortModel[0].sort === 'asc' ? 1 : 2
            };
          }
          this.dashboardService.getTasks(this.userInfo.assumedRoleId, this.DashboardSelectedCard.processId, (this.DashboardSelectedCard.card as { id: number; cardName: string; count: number; }).id, (endRow / this.pageSize), this.pageSize, sort).pipe(
            map(d => {
              const items = d.items.map(i => {
                return { ...i, slainsecs: (this.selectedProcessData.slaInHours * 60 * 60) };
              });
              return { ...d, items };
            }),
            finalize(() => {
              // this.utilityService.setSpinner(false);
            })
          ).subscribe({
            next: (d) => {
              selectedTaskId.emit(null);
              this.updateTime.emit();
              this.afterDataChange();
              if (params.endRow >= d.totalItemCount) {
                lastRow = d.totalItemCount;
              }
              params.successCallback(d.items, lastRow);
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.Type === "HttpRequestException") {
                this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
              }
              else {
                this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
              }
            }
          })
        },
      };
      this.grid.api!.setDatasource(dataSource);
    }
  }

  getSearchData() {
    if (this.searchText.trim().length !== 0) {
      this.utilityService.setGridSpinner(true);
      let lastRow: number = -1;
      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: (params: IGetRowsParams) => {
          const startRow: number = params.startRow;
          const endRow: number = params.endRow;
          let sort: { Field: string, Direction: 1 | 2 } | null = null;
          if (params.sortModel.length > 0) {
            sort = {
              Field: params.sortModel[0].colId === 'timeToSLA' ? 'slaInSeconds' : params.sortModel[0].colId,
              Direction: params.sortModel[0].sort === 'asc' ? 1 : 2
            };
          }
          this.dashboardService.searchTasks(this.DashboardSelectedCard.processId, this.searchText.trim(), (endRow / this.pageSize), this.pageSize, sort).pipe(
            map(d => {
              const items = d.items.map(i => {
                return { ...i, slainsecs: (this.selectedProcessData.slaInHours * 60 * 60) };
              });
              return { ...d, items };
            }),
            finalize(() => {
              this.utilityService.setGridSpinner(false);
            })
          ).subscribe({
            next: (d) => {
              selectedTaskId.emit(null);
              this.updateTime.emit();
              this.afterDataChange();
              if (params.endRow >= d.totalItemCount) {
                lastRow = d.totalItemCount;
              }
              params.successCallback(d.items, lastRow);
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.Type === "HttpRequestException") {
                this.utilityService.showToast.emit({ message: error.error.Title, isError: true });
              }
              else {
                this.utilityService.showToast.emit({ message: error.error.Detail, isError: true });
              }
            }
          })
        },
      };
      this.grid.api!.setDatasource(dataSource);
    }
  }

  afterDataChange() {
    if (this.grid) {
      switch (this.DashboardSelectedCard.card?.id) {
        case 777: // Completed
          this.grid.columnApi.setColumnVisible('endDate', true);
          this.grid.columnApi.setColumnVisible('reorder', false);
          this.grid.columnApi.setColumnVisible('timeToSLA', false);
          break;
        case 0: // My Tasks
        case 2: // In Progress
          this.grid.columnApi.setColumnVisible('endDate', false);
          this.grid.columnApi.setColumnVisible('reorder', false);
          this.grid.columnApi.setColumnVisible('timeToSLA', true);
          break;
        case 10: // In Pending
          this.grid.columnApi.setColumnVisible('endDate', false);
          this.grid.columnApi.setColumnVisible('reorder', false);
          break;
        default:
          this.grid.columnApi.setColumnVisible('endDate', false);
          this.grid.columnApi.setColumnVisible('reorder', true);
          this.grid.columnApi.setColumnVisible('timeToSLA', true);
          break;
      }
    }
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.gridApi.sizeColumnsToFit();
    }
  }



}
