<lib-toast></lib-toast>
<igps-lib-spinner [showSpinner]="(showSpinner)"></igps-lib-spinner>

<!-- Task Dashboard -->
 <section *ngIf="taskSource == 1">
    <app-task-dashboard-grid></app-task-dashboard-grid>
</section>

<!-- Email Dashboard -->
 <section *ngIf="taskSource == 2">
    <app-email-dashboard-grid></app-email-dashboard-grid>
</section>
<!-- <ng-template #showEmailGrid>
    <section>
        <app-email-dashboard-grid></app-email-dashboard-grid>
    </section>
</ng-template> -->


